import React from 'react';
import { ChangeEvent, useRef, useState } from 'react';
import { addStock } from '../../API';
import { blankBatch, ItemType, PeriodType, SupplierType } from '../../common';
import { formatMainDescription, formatPublisher, getMonthAndYearFromDiamondId } from '../../utils/OrderFormats';
import AdminNavigation from '../admin/AdminNavigation';

const DiamondStockUpload: React.FC = () => {
  const today = new Date();
  const month = today.toLocaleString('en-US', { month: 'short' }).toUpperCase();
  const year = String(today.getFullYear()).substring(2,4);
  const formattedDate = `${month}${year}`;

  const [batchName, setBatchName] = useState<string>(formattedDate);
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
    // console.log('clicked');
  };

  const handleBatchName =  (e: ChangeEvent<HTMLInputElement>) => {
    setBatchName(e.currentTarget.value);
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const theFile = e.target.files[0];
    setFile(e.target.files[0]);
    var reader = new FileReader();
    reader.onload = function () {
      const contents = this.result as string;
      const contentsArr = contents ? contents.split('\n') : [""];
      // console.log(contentsArr);
      contentsArr.map(line => {
        const breakdown = line.split(',');
        let triggerRefresh = false;
        if (breakdown.length > 3) {
          const number = parseInt(breakdown[0]);
          const comicId = breakdown[1].substring(1, breakdown[1].length - 3);
          const comicName = breakdown[2].replaceAll('"', '');
          const srp = parseFloat(breakdown[3]) * 100;
          const publisher = breakdown[8].replaceAll('"', '');
          const monthAndYear = getMonthAndYearFromDiamondId(comicId);
          // console.log( number + "  " + comicId + "  " + comicName + "  " + srp + "  " + publisher)
          if (comicId !== 'REVCREDIT' && number && comicId && comicName && srp && publisher) {
            const comic: IComic = {
              _id: '',
              itemType: ItemType.COMIC,
              periodType: PeriodType.NEW,
              supplierType: SupplierType.DIAMOND,
              comicId: comicId,
              srp: srp,
              publisher: formatPublisher(publisher),
              mainDescription: formatMainDescription(comicName),
              variantDescription: '',
              writer: '',
              artist: '',
              coverArtist: '',
              selected: false,
              number: number,
              pnp: 0, 
              releaseMonth: monthAndYear.month,
              releaseYear: monthAndYear.year,
              initialQuantity: number,
              salesQuantity: 0,
              salesValue: 0,
              batch: blankBatch(),
              batchName: batchName,
              images: [],
            }
            // console.log(comic);
            triggerRefresh = true;
            addStock(comic)
              .then(({ status }) => {
                if (status !== 201) {
                  throw new Error('Error! Todo not saved')
                }
              })
              .catch((err) => console.log(err))
          }
        }
        if (triggerRefresh) {
          // handleTriggerRefresh();
        }
        return triggerRefresh;
      });
    };
    reader.readAsText(theFile);
  };



  return (
    <>
      <article>
        <h1>Diamond Stock Upload</h1>
        <div>Upload a file:</div>

        <input onChange={handleBatchName} type='text' id='batchName' value={batchName} />

        {/* 👇 Our custom button to select and upload a file */}
        <button onClick={handleUploadClick}>
          Click to Select
        </button>

        {/* 👇 Notice the `display: hidden` on the input */}
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        {file ? (<div>Uploaded <b>{file.name}</b></div>) : ''}
      </article>
      <nav><AdminNavigation/></nav>
      <aside></aside>
    </>
  );
}

export default DiamondStockUpload