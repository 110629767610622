import React, { useEffect, useState } from 'react';
import BatchDropDown from './BatchDropDown';
import { ItemType, PeriodType, OldBatchType, getItemTypeName, getPeriodTypeName, getPublishersByItemType } from '../../common';
import { getImage, getImageIdUrl } from '../../utils/ImageHandler'
import { formatEbayPriceComic, formatPriceComic, formatPriceSRPRaw } from '../../utils/MoneyFormats';
import { addStockCheck } from '../../API';
import { getMonthAndYearFromDiamondId } from '../../utils/OrderFormats';
import Autocomplete from '../general/Autocomplete';

type Props = {
  formComic: IComic,
  setFormComic: (formComic: IComic) => void,
  itemType: ItemType;
  periodType?: PeriodType;
  batchType: OldBatchType,
  batches: IBatch[] | undefined,
  handleCancel: () => void,
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void,

  // options
  addNewItem: boolean, // off for edit mode
  showSRP: boolean, // on for NEW
  showImages: boolean, // off for NEW
  showEbayPricing: boolean, // on for BACK and GAMEs
  showQuantity: boolean, // off for BACK except edit mode, otherwise on
  showMonth: boolean, // on for NEW
  showYear: boolean, // off for ACCESSORIES
  showSold: boolean, // on for all but NEW (and on edit mode)
  showInitialQuantity: boolean // 
}

const AddGenericItem: React.FC<Props> = ({ formComic, setFormComic, itemType, batches, batchType, handleCancel, handleFormSubmit, showSRP, showImages, showEbayPricing, showQuantity, showMonth, showYear, showSold, showInitialQuantity }) => {

  const [images, setImages] = useState<ImageType[]>(formComic.images);
  const [comicError, setComicError] = useState<IComic>();

  useEffect(() => {
    setImages(formComic.images);
  }, [formComic.images])

  const triggerCheck = (theComic: IComic) => {
    console.log('doing addStockCheck');
    addStockCheck(theComic)
      .then(({ status, data }) => {
        console.log('status ' + status);
        if (status === 200) {
          console.log('data.comic 200 ' + data.comic);
          setComicError(data.comic);
        }
        else if (status === 201) {
          console.log('data.comic 201');
          setComicError(undefined);
        }
        else {
          console.log('data.comic ??? ' + status);
        }
      })
      .catch((err: Error) => console.log('error : ' + err))
  };

  const handleImageNameChange = (idx: any) => (evt: any) => {
    const newImages = images.map((image, sidx) => {
      if (idx !== sidx) return image;
      return { ...image, imageId: evt.target.value };
    });

    setImages(newImages);
    const newComic = { ...formComic, images: newImages };
    setFormComic(newComic);
    triggerCheck(newComic);
  };

  const handleAddImage = () => {
    const newImages = images.concat([{ imageId: "" }]);
    console.log(newImages.length);
    setImages(newImages);
    const newComic = { ...formComic, images: newImages };
    setFormComic(newComic);
    triggerCheck(newComic);
  };

  const handleRemoveImage = (idx: any) => () => {
    const newImages = images.filter((_s, sidx) => idx !== sidx);
    setImages(newImages);
    const newComic = { ...formComic, images: newImages };
    setFormComic(newComic);
    triggerCheck(newComic);
  };

  const handleClearImages = () => {
    setImages([]);
    const newComic = { ...formComic, comicId: '', images: [] };
    setFormComic(newComic);
    triggerCheck(newComic);
  };

  const handleForm = (e: React.FormEvent<HTMLInputElement>, doCheck?: boolean): void => {
    if (e.currentTarget.id === 'comicId' && formComic.periodType === PeriodType.NEW /* batch === Diamond */) {
      const monthAndYear = getMonthAndYearFromDiamondId(e.currentTarget.value);

      const newComic = {
        ...formComic,
        [e.currentTarget.id]: e.currentTarget.value,
        releaseMonth: e.currentTarget.id ? monthAndYear.month : formComic.releaseMonth,
        releaseYear: e.currentTarget.id ? monthAndYear.year : formComic.releaseYear,
      };
      setFormComic(newComic);
      if (doCheck) {
        triggerCheck(newComic);
      }
    }
    else {
      const newComic = {
        ...formComic,
        [e.currentTarget.id]: e.currentTarget.value,
      }
      setFormComic(newComic);
      if (doCheck) {
        triggerCheck(newComic);
      }
    }
  }

  const setPublisher = (publisher: string) => {
    setFormComic({
      ...formComic,
      publisher: publisher
    })
  }

  const setVariantDescription = (variantDescription: string) => {
    setFormComic({
      ...formComic,
      variantDescription: variantDescription
    })
  }
  const clearVariantDescription = () => {
    setFormComic({
      ...formComic,
      variantDescription: ''
    })
  }

  const copyToClipboard = () => {
    var textField = document.createElement('textarea');
    const textToSet = formComic.mainDescription + ' ' + (formComic.releaseYear !== 0 ? formComic.releaseYear + ' ' : '') + formComic.publisher;
    textField.innerText = textToSet;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    console.log('copied ' + textToSet);
  }

  return (
    <div>
      <form className='comic-update' onSubmit={handleFormSubmit}>
        {comicError && comicError.comicId}
        {formComic.comicId ?
          (<div className="right"><img className='comic-item-preview-lge-cover' alt='cover' src={getImage(formComic)} /></div>) : ''}

        <input type='text' className='inline' id='mainDescription' onChange={handleForm} onBlur={() => triggerCheck(formComic)} value={formComic.mainDescription}
          placeholder={getItemTypeName(formComic.itemType as ItemType) + (formComic.periodType !== undefined ? ' ' + getPeriodTypeName(formComic.periodType as PeriodType) : '') + ' Description'} />
        {(comicError?.mainDescription === formComic.mainDescription && comicError?.variantDescription === formComic.variantDescription) && <div className='alert'>!</div>}
        {(comicError?.mainDescription === formComic.mainDescription && comicError?.variantDescription !== formComic.variantDescription) && <div className='warning'>!</div>}
        <br />
        {/* <input type='text' className='inline' id='variantDescription' onChange={handleForm} onBlur={() => triggerCheck(formComic)} value={formComic.variantDescription} placeholder='Variant Description' /><br /> */}
        <Autocomplete
          value={formComic.variantDescription}
          setValue={setVariantDescription}
          onBlur={() => triggerCheck(formComic)}
          placeholder='Variant Description'
          suggestions={['okay condition', 'poor condition']}
          className={'comic-update-smaller'}
        >
          {/* eslint-disable-next-line*/}
          <a onClick={clearVariantDescription} href="#">clr</a><br />
        </Autocomplete>
        <input type='text' className='inline' id='comicId' onChange={(event) => { handleForm(event, true); }} value={formComic.comicId} placeholder='Image ID' />
        {/* <input type='text' className='inline' id='ebaySku' onChange={(event) => { handleForm(event, true); }} value={formComic.ebaySku} placeholder='Ebay SKU' /> */}
        {(comicError !== undefined && comicError.images !== undefined && comicError.images.filter((i) => i.imageId === formComic.comicId).length >= 1) && <div className='alert'>!</div>}<br />

        {/* <input type='text' className='inline' id='publisher' onChange={handleForm} value={formComic.publisher} placeholder='Publisher' /><br /> */}
        <Autocomplete
          value={formComic.publisher}
          setValue={setPublisher}
          placeholder='Publisher'
          suggestions={getPublishersByItemType(itemType)}
        />
        <div className='comic-batchname-input inline'>
          <BatchDropDown batches={batches} batchType={batchType} comic={formComic} setComic={setFormComic} />
        </div>
        {showMonth && <input type='text' className='comic-month-input inline' id='releaseMonth' onChange={handleForm} value={formComic.releaseMonth} placeholder='Month' />}
        {showYear && <input type='text' className='comic-year-input inline' id='releaseYear' onChange={handleForm} value={formComic.releaseYear} placeholder='Year' />}
        <span className='copy-icon-pulse'><img src='/img/icons/copy.svg' alt="copy to clipboard" className='copy-icon' onClick={copyToClipboard} /></span>
        <h1>
          <div className="margin-bottom-medium">
            {showSRP && (<>&nbsp;&nbsp;&nbsp;&nbsp;<span className='strikethrough'>${formatPriceSRPRaw(formComic.srp)}</span>&nbsp;&nbsp;</>)}
            $<input type='number' className='comic-price-input inline ' id='priceOverride' onChange={handleForm} value={formComic.priceOverride} placeholder='Price' />
            $<input type='number' className='comic-price-input inline ' id='pnp' onChange={handleForm} value={formComic.pnp} placeholder='P&P' />
          </div>
          <div className="margin-bottom-less">
            {showEbayPricing && (<>${formatEbayPriceComic(formComic, formComic.batch.discountPercent)}&nbsp;</>)}
            [${formatPriceComic(formComic, formComic.batch.discountPercent)}]
            {(formComic.batch.discountPercent && formComic.batch.discountPercent > 0) ? <>&nbsp;at {formComic.batch.discountPercent}%</> : ''}
          </div>
          <div>
            {(showInitialQuantity) && <>x<input type='number' className='comic-price-input inline auto-width' id='initialQuantity' onChange={handleForm} value={formComic.initialQuantity} placeholder='Initial' /></>}
            {(showQuantity) && <>x<input type='number' className='comic-price-input inline auto-width' id='number' onChange={handleForm} value={formComic.number} placeholder='Quantity' /></>}
            {(showSold) && <>x<input type='number' className='comic-price-input inline ' id='salesQuantity' onChange={handleForm} value={formComic.salesQuantity} placeholder='sold' /></>}
          </div>
        </h1>
        {showImages ?
          <div>
            {images.map((image, idx) => image ? (
              <div className="comic-item-preview-add-image-container" key={idx}>
                {image.imageId && <div className="right"><img className='comic-item-preview-sml-cover' alt='cover' src={getImageIdUrl(formComic, image.imageId)} /></div>}
                <div className="comic-item-preview-add-image-field inline">
                  <input
                    type="text"
                    placeholder={`Image #${idx + 1} name`}
                    value={image.imageId}
                    onChange={handleImageNameChange(idx)}
                  />
                </div>
                <button type="button" onClick={handleRemoveImage(idx)} className="small inline"> - </button>
                {(comicError && comicError.images.filter((i) => i.imageId === image.imageId).length >= 1) && (<>&nbsp;&nbsp;&nbsp;<div className='alert'>!</div></>)}
              </div>
            ) : (<></>))}
            <hr />
            <button type="button" onClick={handleAddImage} className="link-button add-image-button">add image</button>
            <span className='right'>&nbsp;&nbsp;•&nbsp;&nbsp;</span>
            <button type="button" onClick={handleClearImages} className="link-button clear-images-link">clear</button>
          </div> :
          <><hr /></>}
        <div>
          <button className="link-button" onClick={() => handleCancel()} type='button'>cancel</button>&nbsp;&nbsp;•&nbsp;&nbsp;
          <button className="link-button" type="submit" disabled={comicError !== undefined}>save</button>
          {comicError && <span className='right'><button className="link-button" type="submit">save anyway</button>&nbsp;&nbsp;•&nbsp;&nbsp;</span>}
          {/* <EbayControls comic={formComic} setComic={setFormComic} setResult={setResult}/>
          {result !== '' ? (<div>{result}</div>) : ''} */}
        </div>
      </form>
    </div>
  );
}

export default AddGenericItem