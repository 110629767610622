import React, { useEffect, useState } from 'react'
import CheckListItem from './CheckListItem'
import { getCheckListItems, updateCheckList, deleteCheckList, addCheckListItem, getCheckListDeleteAll } from '../../../API'
import { State } from '../../../App'
import AdminNavigation from '../../admin/AdminNavigation'
import AddCheckListItem from './AddCheckListItem'

type Props = {
  state: State,
  setState: any,
}

const AdminCheckList: React.FC<Props> = () => {
  const [checkListItems, setCheckListItems] = useState<ICheckList[]>([])

  useEffect(() => {
    fetchCheckList()
  }, [])

  const fetchCheckList = (): void => {
    // console.log(`fetchCheckLists()`);
    getCheckListItems()
      .then(({ data: { checkListItems } }: ICheckList[] | any) => setCheckListItems(checkListItems))
      .catch((err: Error) => console.log(err))
  }

  const handleDeleteAllCheckList = (): void => {
    // console.log(`fetchCheckLists()`);
    getCheckListDeleteAll()
      .then(({ data: { checkListItems } }: ICheckList[] | any) => setCheckListItems(checkListItems))
      .catch((err: Error) => console.log(err))
  }

  const handleSaveCheckList = (e: React.FormEvent, formData: ICheckList): void => {
    e.preventDefault()
    addCheckListItem(formData)
      .then(({ status, data }) => {
        if (status !== 201) {
          throw new Error('Error! CheckList not saved')
        }
        setCheckListItems(data.checkListItems)
      })
      .catch((err) => console.log(err))
  }

  const handleUpdateCheckList = (checkListItem: ICheckList): void => {
    updateCheckList(checkListItem)
      .then(({ status, data }) => {
        if (status !== 200) {
          throw new Error('Error! CheckList not updated')
        }
        setCheckListItems(data.checkListItems)
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteCheckList = (_id: string): void => {
    deleteCheckList(_id)
      .then(({ status, data }) => {
        if (status !== 200) {
          throw new Error('Error! CheckList not deleted')
        }
        setCheckListItems(data.checkListItems)
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>Check List ({checkListItems.length})</h1>
          <AddCheckListItem saveCheckList={handleSaveCheckList} />
          <button
            onClick={handleDeleteAllCheckList}
            className='link-button'
          >delete all</button>
          {checkListItems && checkListItems.map((checkListItem: ICheckList) => (
            <CheckListItem
              key={checkListItem._id}
              updateCheckList={handleUpdateCheckList}
              deleteCheckList={handleDeleteCheckList}
              checkListItem={checkListItem}
            />
          ))}
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AdminCheckList
