// import swal from 'sweetalert2';

import { ItemType, OldComicType, PeriodType } from "../common";
import { AMAZON_S3 } from "./UrlConsts";

export const getImageUrl = (comic: IComic) => {
  const image =
    comic.itemType === ItemType.GAME || comic.itemType === ItemType.ACCESSORY ? AMAZON_S3 + '/games/' + comic.comicId + '.webp' :
      comic.periodType === PeriodType.BACK_ISSUE ? AMAZON_S3 + '/backissues/' + comic.comicId + '.webp' :
        AMAZON_S3 + '/diamond/' + comic.comicId + '.webp';

  return image;
}

export const getImageIdUrl = (comic: IComic, imageId: string) => {
  const image =
    comic.itemType === ItemType.GAME || comic.itemType === ItemType.ACCESSORY ? AMAZON_S3 + '/games/' + imageId + '.webp' :
      comic.periodType === PeriodType.BACK_ISSUE ? AMAZON_S3 + '/backissues/' + imageId + '.webp' :
        AMAZON_S3 + '/diamond/' + imageId + '.webp';

  return image;
}

export const getImage = (comic: IComic): string => {
  return getImageUrl(comic);
}

export const getImageCss = (comic: IComic): string => {
  return comic.itemType === ItemType.GAME || comic.itemType === ItemType.ACCESSORY ? 'comic-item-cover-game' :
    comic.periodType === PeriodType.BACK_ISSUE ? 'comic-item-cover-bi' : 'comic-item-cover';
}

export const getImageCssOrderItem = (orderItem: IOrderItem): string => {
  return orderItem.itemType === ItemType.GAME || orderItem.itemType === ItemType.ACCESSORY ? 'comic-item-cover-game' :
    orderItem.periodType === PeriodType.BACK_ISSUE ? 'comic-item-cover-bi' : 'comic-item-cover';
}


export const getOrderItemUrl = (orderItem: IOrderItem) => {

  // console.log( 'orderItem.name ' + orderItem.name );
  // console.log( 'orderItem.periodType ' + orderItem.periodType );
  // console.log( 'orderItem.type ' + orderItem.type );
  if (orderItem.periodType) {
    const image =
      orderItem.itemType === ItemType.GAME || orderItem.itemType === ItemType.ACCESSORY ? AMAZON_S3 + '/games/' + orderItem.comicId + '.webp' :
        orderItem.periodType === PeriodType.BACK_ISSUE ? AMAZON_S3 + '/backissues/' + orderItem.comicId + '.webp' :
          AMAZON_S3 + '/diamond/' + orderItem.comicId + '.webp';

    return image;
  }
  else if (orderItem.type) {
    const image =
      orderItem.type === OldComicType.NEW ? AMAZON_S3 + '/diamond/' + orderItem.comicId + '.webp' :
        orderItem.type === OldComicType.BACK ? AMAZON_S3 + '/backissues/' + orderItem.comicId + '.webp' :
          AMAZON_S3 + '/games/' + orderItem.comicId + '.webp';

    return image;
  }
  else {
    // this is legacy code for when we didn't have orderItem.type
    const image =
      orderItem.comicId.indexOf('PXL') === -1 ?
        AMAZON_S3 + '/diamond/' + orderItem.comicId + '.webp' :
        AMAZON_S3 + '/backissues/' + orderItem.comicId + '.webp';

    return image;
  }
}
