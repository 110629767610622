import React, { useEffect, useState } from 'react'
import OrdersProcessedtem from './OrdersProcessedtem'
import { getOrdersProcessed } from '../../../API'
import { State } from '../../../App'
import AdminNavigation from '../../admin/AdminNavigation'

type Props = {
  state: State,
  setState: any,
}

const AdminOrdersProcessed: React.FC<Props> = () => {
  const [checkListItems, setOrdersProcessedItems] = useState<IEbayOrder[]>([])

  useEffect(() => {
    fetchOrdersProcessed()
  }, [])

  const fetchOrdersProcessed = (): void => {
    // console.log(`fetchOrdersProcesseds()`);
    getOrdersProcessed()
      .then(({ data: { checkListItems } }: IEbayOrder[] | any) => setOrdersProcessedItems(checkListItems))
      .catch((err: Error) => console.log(err))
  }

  // const handleSaveOrdersProcessed = (e: React.FormEvent, formData: IEbayOrder): void => {
  //   e.preventDefault()
  //   addOrdersProcessedItem(formData)
  //     .then(({ status, data }) => {
  //       if (status !== 201) {
  //         throw new Error('Error! OrdersProcessed not saved')
  //       }
  //       setOrdersProcessedItems(data.checkListItems)
  //     })
  //     .catch((err) => console.log(err))
  // }

  // const handleDeleteOrdersProcessed = (_id: string): void => {
  //   deleteOrdersProcessed(_id)
  //     .then(({ status, data }) => {
  //       if (status !== 200) {
  //         throw new Error('Error! OrdersProcessed not deleted')
  //       }
  //       setOrdersProcessedItems(data.checkListItems)
  //     })
  //     .catch((err) => console.log(err))
  // }

  return (
    <>
      <article>
        <h1>Ebay Orders Processed</h1>
          {/* <AddOrdersProcessedItem saveOrdersProcessed={handleSaveOrdersProcessed} /> */}
          {checkListItems && checkListItems.map((order: IEbayOrder, idx) => (
            <OrdersProcessedtem
              key={idx}
              // deleteOrdersProcessed={handleDeleteOrdersProcessed}
              order={order}
            />
          ))}
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AdminOrdersProcessed
