import React, { useEffect, useState } from 'react'
import ViewEditStockItemSwitcher from './ViewEditStockItemSwitcher'
import { deleteStock, getStocksAdmin, updateStock } from '../../API'
import AdminNavigation from '../admin/AdminNavigation'
import { getPriceComicB } from '../../utils/MoneyFormats'
import Swal from 'sweetalert2'
import { formatMoney } from '../../utils/MoneyFormats'
import './AllStockList.scss'
import { ItemType, PeriodType, getItemTypeName, getPeriodTypeName } from '../../common'
import BatchFilter, { matchBatchToItemAndPeriod } from '../general/BatchFilter'
import { useParams } from 'react-router-dom'

type Props = {
  itemType: ItemType,
  periodType?: PeriodType,
  batches: IBatch[],
}

const AdminStockList: React.FC<Props> = ({ itemType, periodType, batches }) => {
  
  const { id } = useParams();

  const findBatch = (batches: IBatch[], id?: string) => {
    console.log('batches = ' + batches.length)
    console.log('id = ' + id)

    let batch = batches.find(batch => id === batch._id);
    console.log('batch = ' + batch)
    if (!batch) {
      batch = batches.find(batch => matchBatchToItemAndPeriod(batch, itemType, periodType));
      console.log('batch2 = ' + batch)
    }

    return batch ? batch.name : 'all';
  }

  const [stock, setStocks] = useState<IComic[]>([])
  const [batchFilter, setBatchFilter] = useState('all');
  
  const getBatch = () => {
    return batches.find(batch => batch.name === batchFilter);
  }

  //  const [secondTime, setSectionTime] = useState(false);

  const fetchStocks = (batchFilter: string): void => {
    // console.log(`fetchStocks() ` + batchFilter);
    getStocksAdmin(itemType, periodType, 1, 2000, batchFilter)
      .then(({ data: { comics } }: IComic[] | any) => setStocks(comics))
      .catch((err: Error) => console.log(err))
  }

  useEffect(() => {
    console.log(`comicType has changed`);
    setBatchFilter(findBatch(batches, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemType, periodType, batches])

  useEffect(() => {
    fetchStocks(batchFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchFilter])

  const refreshStock = () => {
    fetchStocks(batchFilter);
  }

  const handleUpdateStock = (comic: IComic): void => {
    // console.log(comic);
    updateStock(comic)
      .then(({ status }) => {
        if (status !== 200) {
          throw new Error('Error! Stock not updated')
        }
        fetchStocks(batchFilter);
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteStock = (comic: IComic): void => {
    if (comic._id !== undefined) {

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {

          if (comic._id !== undefined) {
            deleteStock(comic._id)
              .then(({ status }) => {
                if (status !== 200) {
                  throw new Error('Error! Stock not updated')
                }
                fetchStocks(batchFilter);

                Swal.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
                )
              })
              .catch((err) => console.log(err))
            Swal.fire(
              'NOT Deleted!',
              'Your file was NOT deleted.',
              'error'
            )
          }
        }
      })
    }
  }

  const ebayToGo = stock.reduce((partialSum, comic) => partialSum + (comic.ebaySku ? 0 : 1), 0);

  return (

    <>
      <article>
        <h1>
          {getItemTypeName(itemType)} {periodType && getPeriodTypeName(periodType)} ({stock.length} unique,&nbsp;
          {stock.reduce((partialSum, comic) => partialSum + (comic.number ? comic.number : 0), 0)} total,
          ${formatMoney(stock.reduce((partialSum, comic) => partialSum + (comic.number ? comic.number * getPriceComicB(comic, comic.batch) : 0), 0))})
        </h1>
        <div>
          uploaded: {ebayToGo === 0 ? `all done` : stock.reduce((number, comic) => number + (comic.ebaySku ? 1 : 0), 0) + ` done `}
          {ebayToGo > 0 && ` (` + ebayToGo + ` to go`}
          {ebayToGo !== 0 && ` at $` + formatMoney(stock.reduce((partialSum, comic) => partialSum + (comic.ebaySku ? 0 : comic.number * getPriceComicB(comic, comic.batch)), 0))}
          {ebayToGo > 0 && `)`}
          {batchFilter !== 'all' && <><br/>sold: {stock.reduce((number, comic) => number + (comic.salesQuantity), 0)} at ${formatMoney(stock.reduce((number, comic) => number + (comic.salesQuantity * comic.salesValue), 0))}</>}
          {batchFilter !== 'all' && <><br/>value: ${formatMoney(getBatch()?.value)}</>}
        </div>
        <br />
        <div className='link-small'>
          filter: <BatchFilter batches={batches} itemType={itemType} periodType={periodType} batchFilter={batchFilter} setBatchFilter={setBatchFilter} />
        </div>
        <br />
        {stock && stock.map((comic: IComic) => (
          <ViewEditStockItemSwitcher
            key={comic._id}
            batches={batches}
            updateStock={handleUpdateStock}
            deleteStock={handleDeleteStock}
            comic={comic}
          />
        ))}
      </article>
      <nav><AdminNavigation /></nav>
      <aside>
        <span className="top-30">
          <button className='link-button' onClick={() => refreshStock()}>Refresh</button>
        </span>
      </aside>
    </>
  )
}

export default AdminStockList
