import React, { useState } from 'react'
import AdminNavigation from './AdminNavigation'
import eBayApi from 'ebay-api';
import { getEbayList, getEbayRefreshBatch, getEbaySearch, getEbayUpdateTransactions, getEbayUpdateTransactionsDontSave, getEbayUploadBatch } from '../../API';
// import { Condition } from 'ebay-api/lib/enums';

type Props = {
  eBay: eBayApi;
  batches: IBatch[],
}
const AdminEbay: React.FC<Props> = ({ eBay, batches }) => {

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const codeParam = params.get('code');
  const [code] = useState(codeParam ? codeParam : '');
  // const [token, setToken] = useState();
  const [ebayUrl, setEbayUrl] = useState('');
  const [result, setResult] = useState('');
  const [batchName, setBatchName] = useState(batches && batches.length > 0 ? batches[0].name : '');
  const [batchQuantity, setBatchQuantity] = useState(10);

  const handleGetEbayUrl = () => {
    eBay.auth.oAuth2.setScope([
      'https://api.ebay.com/oauth/api_scope',
      'https://api.ebay.com/oauth/api_scope/sell.marketing.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.marketing',
      'https://api.ebay.com/oauth/api_scope/sell.inventory.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.inventory',
      'https://api.ebay.com/oauth/api_scope/sell.account',
      'https://api.ebay.com/oauth/api_scope/sell.account.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.fulfillment',
      'https://api.ebay.com/oauth/api_scope/sell.analytics.readonly',
      'https://api.ebay.com/oauth/api_scope/sell.finances',
      'https://api.ebay.com/oauth/api_scope/sell.payment.dispute',
      'https://api.ebay.com/oauth/api_scope/commerce.identity.readonly',
    ]);

    // 2. Generate and open Url and Grant Access
    setEbayUrl(eBay.OAuth2.generateAuthUrl());
    console.log('Open URL', ebayUrl);
  }

  const handleSearch = () => {
    getEbaySearch(code)
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message) })
  }

  const handleListItems = () => {
    getEbayList(code)
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message) })
  }

  const handleUploadBatch = () => {
    getEbayUploadBatch(batchName, batchQuantity)
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message) })
  }
  
  const handleRefreshBatch = () => {
    getEbayRefreshBatch(batchName, batchQuantity)
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message) })
  }

  const handleUpdateTransactions = () => {
    getEbayUpdateTransactions()
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message) })
  }

  const handleUpdateTransactionsDontSave = () => {
    getEbayUpdateTransactionsDontSave()
      .then(({ data }) => {
        // console.log(`status: ` + status);
        // console.log(`data: ` + data);
        // console.log(`status: ` + data.message);
        setResult(JSON.stringify(data.message));
      })
      .catch((err) => { console.log(err); setResult(err.message) })
  }

  const handleBatchQuantiyChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setBatchQuantity(parseInt(e.currentTarget.value));
  }
  const handleBatchNameChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    setBatchName(e.currentTarget.value);
  }

  return (
    <>
      <article>
        <h1>eBay Admin</h1>
        <br /><br />
        <div>
          {ebayUrl === '' ?
            (<><button className='link-button' onClick={handleGetEbayUrl}>Get Ebay URL</button></>) :
            (<><a href={ebayUrl}>Login To Ebay</a></>)}
          {window.location.href.indexOf('localhost') === -1 && code ? (<> • <a href={'http://localhost:3000/admin-ebay?code=' + encodeURIComponent(code)}>Pass to LOCALHOST</a></>) : ''}
          <br /><br />
          <button className='link-button' onClick={handleSearch}>Search</button><br /><br />
          <button className='link-button' onClick={handleListItems}>List Items</button><br /><br />
          <button className='link-button' onClick={handleUpdateTransactions}>Update Transactions</button>&nbsp;•&nbsp;
          <button className='link-button' onClick={handleUpdateTransactionsDontSave}>Test</button><br /><br />
          <select value={batchName} onChange={handleBatchNameChange} className="batch-name-input">
            {batches && batches.map((batch, key) => <option key={key}>{batch.name}</option> )}
          </select>
          <input type="text" onChange={handleBatchQuantiyChange} value={batchQuantity} className="batch-quantity-input"></input>
          <button className='link-button' onClick={handleUploadBatch}>Upload Batch</button>&nbsp;•&nbsp;<button className='link-button' onClick={handleRefreshBatch}>Refresh Batch</button><br /><br />
        </div>
        <h1>{result}</h1>
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>
  )
}

export default AdminEbay