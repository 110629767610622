import React from 'react'

type Props = CheckListProps & {
  updateCheckList: (checkListItem: ICheckList) => void
  deleteCheckList: (_id: string) => void
}

const CheckListItem: React.FC<Props> = ({ checkListItem, updateCheckList, deleteCheckList }) => {
  const checkCheckList: string = checkListItem.status ? `line-through` : ''
  return (
    <>
      <div className='Card'>
        <div className='Card--text'>
          <h1 className={checkCheckList}>{checkListItem.name}</h1>
          <span className={checkCheckList}>{checkListItem.description}</span>
        </div>
        <div className='Card--button'>
          <button
            onClick={() => updateCheckList(checkListItem)}
            className={checkListItem.status ? `hide-button` : 'Card--button__done'}
          >
            Complete
          </button>
          <button
            onClick={() => deleteCheckList(checkListItem._id)}
            className='Card--button__delete'
          >
            Delete
          </button>
        </div>
      </div>
    </>
  )
}

export default CheckListItem
