import React from 'react'
import { completeOrder, updateOrder } from '../../API'
import './MainFeed.scss';
import swal from 'sweetalert2';
import { updateOrderState } from '../../utils/OrderCalculations';
import { State } from '../../App';
import { getAdminFromStorage } from '../../utils/UserHandler';
import './OrderList.scss'
import { formatMoney } from '../../utils/MoneyFormats';
import OrderListItem from './OrderListItem';

type Props = {
  state: State,
  setState(state: State): void,
  order: IOrder,
  setOrder(order: IOrder): void,
  libraries: string[],
  batches: IBatch[],
  firstOrderItemRef: any,
  transactions: ITransaction[],
  setTransactions(transactions: ITransaction[]): void,
  credit: number,
  setCredit(credit: number): void,
  mobile: boolean,
}

const OrderList: React.FC<Props> = ({ state, setState, order, setOrder, firstOrderItemRef, mobile, transactions, setTransactions, credit, setCredit }) => {


  const update = (order: IOrder, transactions: ITransaction[], credit: number) => {

    transactions = transactions ? transactions : [];
    setTransactions(transactions);
    credit = credit ? credit : 0;
    setCredit(credit);

    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));

    // console.log('transactions' + JSON.stringify(transactions))
    // setTransactions(transactions ? transactions : []);
    updateState(order, transactions, credit);

    addHighlightToFirstOrderItem();
  }

  const increaseOrderItem = (orderItem: IOrderItem): void => {
    orderItem.quantity++;
    // console.log(orderItem.name);
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }

  const decreaseOrderItem = (orderItem: IOrderItem): void => {
    orderItem.quantity--;
    // console.log(orderItem.name);
    if (orderItem.quantity < 1) {
      // TODO should be done on server, but doing here for now
      order.orderItems = order.orderItems.filter((thisOrderItem: IOrderItem) => thisOrderItem.name !== orderItem.name);
    }
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }


  const handleUpdateOrder = (): void => {
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }

  const handleDeleteOrderItem = (orderItem: IOrderItem): void => {
    // console.log(orderItem.name);
    order.orderItems = order.orderItems.filter((thisOrderItem: IOrderItem) => thisOrderItem.name !== orderItem.name);
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }

  const handleHideMobileOrder = () => {
    setState({ ...state, showMobileOrder: false });
  }

  const handleOrderNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setOrder({ ...order, name: e.currentTarget.value });
  }
  const addHighlightToFirstOrderItem = (): void => {
    if (firstOrderItemRef && firstOrderItemRef.current) {
      firstOrderItemRef.current.className = 'order-item highlight-order-item';
    }
  }

  const updateState = (order: IOrder, transactions: ITransaction[], credit: number) => {
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
  }

  const handleOpenPaymentDialog = () => {
    setState({ ...state, showPaymentDialog: true })
  }

  const handleCompleteOrder = (): void => {

    order.latestTransaction = undefined;
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));

    completeOrder(order)
      .then(({ status, data: { order, transactions, credit, errorMessage } }: any) => {
        // console.log(status);
        if (status === 200) {
          update(order, transactions, credit);
          swal.fire("Success", "success", "success").then(() => {

            setState({ ...state, showPaymentDialog: true })
            window.location.reload();
          });
        }
        else {
          swal.fire("Failure", errorMessage, 'error').then(() => {

            setState({ ...state, showPaymentDialog: true })
            window.location.reload();
          });
        }
      })
      .catch(function (error: any) {
        console.log('error1 = ' + error.response);
        // console.log('error2 = ' + error.response.data);
        // console.log('error3 = ' + error.response.data.errorMessage);
        swal.fire("Failure", 'fail', 'error');
      })
  }

  const isMobileClassName = mobile ? 'mobile-order-list' : '';

  return (
    <>
      <div className={isMobileClassName}>
        <div className="order-box-wrapper">
          <div className='order-box'>
            <div className='order-title-top-container'>
              <div className='order-title-top'>&nbsp;</div>
            </div>
            <div className='order-title-bottom'>
              Your Order
              {mobile ? (<button className='image-button close-mobile-order-button' onClick={handleHideMobileOrder}><img className='close-mobile-order-image' src='/img/icons/x.svg' alt='delete' /></button>) : ''}
            </div>
            <div className='order-list'>
              {order.orderItems !== undefined && order.orderItems.length > 0 ?
                order.orderItems.map((orderItem: IOrderItem) => (
                  <OrderListItem
                    firstOrderItemRef={firstOrderItemRef}
                    orderItemIndex={order.orderItems.indexOf(orderItem)}
                    key={orderItem.comicId}
                    increaseOrderItem={increaseOrderItem}
                    decreaseOrderItem={decreaseOrderItem}
                    deleteOrderItem={handleDeleteOrderItem}
                    orderItem={orderItem}
                  />
                )) :
                (<div className="centre purchase-info-wrapper">
                  <div className='purchase-info'><br /><br /><br />Click the<br /><br /></div>
                  <div><img src="/img/icons/purchase.svg" className='big-purchase-icon' alt='purchase' /><br /><br /></div>
                  <div className='purchase-info'>shopping trolley to<br />add to your order</div>
                </div>)
              }
            </div>
            {order.itemCount >= 1 ?
              (<>
                <div className='payment-summary'>
                  <div>
                    {order.creditFrom > 0.01 ? (<div className='payment-summary-extra'>Previous Credit ${formatMoney(order.creditFrom)}</div>) : ""}
                    <div className='payment-summary-extra'>Total {order.itemCount > 1 ? "x" + order.itemCount + " items at" : ""} ${formatMoney(order.total)}</div>
                    <div className='payment-summary-extra'>Postage ${formatMoney(order.pnp)}</div>
                    {order.paid > 0 ? (<div className='payment-summary-extra'>paid ${formatMoney(order.paid)}</div>) : ""}
                    {order.toPay > 0.01 ? (<div className='payment-summary-extra'>To pay <b>${formatMoney(order.toPay)}</b></div>) : ""}
                    {order.toPay <= 0.01 && order.total >= 0.01 ? (<div className='payment-summary-extra'><b>All paid!</b></div>) : ""}
                    {order.creditForward > 0.01 && order.total >= 0.01 ? (<div className='payment-summary-extra'>Credit ${formatMoney(order.creditForward)}</div>) : ""}                  </div>
                </div>
                {getAdminFromStorage() ?
                  (<div className='payment-button-container'>
                    <input className='Form' value={order.name} onChange={handleOrderNameChange} onBlur={handleUpdateOrder} />
                  </div>) : ''}
                <div className='payment-button-container'>
                  {getAdminFromStorage() && order.name !== '' ? (
                    <button
                      onClick={handleCompleteOrder ? () => handleCompleteOrder() : () => { }}
                      className='standard-button'
                      disabled={!(order.orderItems !== undefined && order.orderItems.length > 0)}
                    >
                      Ad-hoc Sale
                    </button>
                  ) :
                    order.toPay <= 0.01 && order.total >= 0.01 ?
                      (<button
                        onClick={handleOpenPaymentDialog ? () => handleOpenPaymentDialog() : () => { }}
                        className='standard-button'
                        disabled={!(order.orderItems !== undefined && order.orderItems.length > 0)}
                      >
                        Proceed to Shipping
                      </button>
                      ) :
                      (
                        <button
                          onClick={handleOpenPaymentDialog ? () => handleOpenPaymentDialog() : () => { }}
                          className='standard-button'
                          disabled={!(order.orderItems !== undefined && order.orderItems.length > 0)}
                        >
                          Proceed to Payment
                        </button>
                      )}
                </div>
              </>) : ''}
          </div>
          <div className='payment-button-container-bottom'>
            &nbsp;
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderList
