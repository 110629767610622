import React, { useEffect, useState } from 'react'
import { getOrders } from '../../API'
import AccountNavigation from './AccountNavigation'
import AccountOrderHistoryItem from './AccountOrderHistoryItem'
import { State } from '../../App'
import AccountOrderHistoryEditableItem from './AccountOrderHistoryEditableItem'
type Props = {
  state: State,
  setState: any,
  currentOrder: IOrder
  setOrder(order: IOrder): void,
  libraries: string[],
  batches: IBatch[],
  firstOrderItemRef: any,
  // transactions: ITransaction[],
  setTransactions(transactions: ITransaction[]): void,
  // credit: number,
  setCredit(credit: number): void,
}

const AccountOrderHistoryList: React.FC<Props> = ({ state, setState, currentOrder, setOrder, libraries, batches, firstOrderItemRef, setTransactions, setCredit }) => {
  const [orders, setOrders] = useState<IOrder[]>([])

  useEffect(() => {
    fetchOrders()
  }, [])

  const fetchOrders = (): void => {
    // console.log(`fetchOrders()`);
    getOrders()
      .then(async ({ data: { orders } }: IOrder[] | any) => {
        setOrders(orders);
      })
      .catch((err: Error) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>Your Orders</h1>
        <br />
        <div className='column-centre'>
          {/* <AddOrder saveOrder={handleSaveOrder} /> */}
          {currentOrder.orderItems.length > 0 &&
            <AccountOrderHistoryEditableItem
              order={currentOrder}
              setOrder={setOrder}
              state={state}
              setState={setState}
              libraries={libraries}
              batches={batches}
              firstOrderItemRef={firstOrderItemRef}
              setTransactions={setTransactions}
              setCredit={setCredit}
            />}
          {orders && orders.map((order: IOrder) => (
            <AccountOrderHistoryItem
              state={state}
              setState={setState}
              key={order._id}
              order={order}
            />
          ))}
        </div>
      </article>
      <nav className="hide-in-tablet"><AccountNavigation /></nav>
      <aside className="hide-in-tablet"></aside>
    </>
  )
}

export default AccountOrderHistoryList