import React, { useState } from 'react'
import { userSendCredit } from '../../API'
import Swal from 'sweetalert2'
import { handleGenericError } from '../../utils/ErrorHandler'

type Props = {
  targetUserId: string,
  handleCloseCreditDialog: () => void
}

export type ICreditNote = {
  targetUserId: string,
  amount: number,
  description: string,
}

const CreditDialog: React.FC<Props> = ({ targetUserId, handleCloseCreditDialog }) => {

  const [formData, setFormData] = useState<ICreditNote>({
    targetUserId: targetUserId,
    amount: 0,
    description: '',
  })

  const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  const handleAreaForm = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    setFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  const handleSendCredit = (e: React.FormEvent): void => {
    e.preventDefault();
    userSendCredit(formData)
      .then(({ status, data }) => {
        if (status === 200) {
          Swal.fire("Success", data.message, "success").then(() => {
            handleCloseCreditDialog();
          }); 
        }
        else {
          Swal.fire("Fail", data.message, "error");
        }
      })
      .catch((data) => {
        handleGenericError(data.message ? data.errorMessage : "unknown error");
      })
  }

  return (
    <>
      <div className='grey-background'></div>
      <div className="modal-flex">
        <div className='dialog'>
          <button
            onClick={() => handleCloseCreditDialog()}
            className='image-button dialog-close-button'
          >
            <img src='/img/icons/x.svg' alt='delete' />
          </button>
          <h3>Send Credit</h3>

          <form className='Form' onSubmit={(e) => handleSendCredit(e)}>
            <div>
              <label htmlFor='amount' className='block'>Amount</label>
              <input onChange={handleForm} type='text' id='amount' />
            </div>
            <div>
              <label htmlFor='description' className='block'>Explanation</label>
              <textarea onChange={handleAreaForm} id='description' />
            </div>
            <br />
            <div className='payment-dialog-button-container'>
              <button className='standard-button'>
                Send Credit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default CreditDialog
