import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import './ResetPassword.scss'
import { resetPassword } from '../../API';
import swal from 'sweetalert2';
import { handleGenericError } from '../../utils/ErrorHandler';

const ResetPassword: React.FC = () => {

  const { token } = useParams();

  console.log('token = ' + token);

  const navigate = useNavigate();
  const [passwordsFormatMinChars, setPasswordsFormatMinChars] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [bothEntered, setBothEntered] = useState(false);
  const [formState, setFormState] = useState({
    primary: '',
    secondary: '',
  })

  const handleForm = (e: React.FormEvent<HTMLInputElement>): any => {
    if (e.currentTarget.id === 'primary') {
      const newState = {
        ...formState,
        primary: e.currentTarget.value
      }
      setFormState(newState);
      return newState;
    }
    else {
      const newState = {
        ...formState,
        secondary: e.currentTarget.value
      }
      setFormState(newState);
      return newState;
    }
  }

  const performMinorCheck = (state: any) => {
    // check format of password
    console.log('primary.length ' + state.primary.length)
    setPasswordsFormatMinChars(state.primary.length >= 8);
  }

  const performMajorCheck = (state: any) => {
    // check passwords match
    performMinorCheck(state);
    setPasswordsMatch(state.primary === state.secondary);
  }

  const handlePrimaryOnBlur = () => {
  }

  const handlePrimaryOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    console.log('handPrimaryOnChange')
    const state = handleForm(e);
    if (bothEntered) {
      performMajorCheck(state);
    }
    else {
      performMinorCheck(state);
    }
  }

  const handleSecondaryOnBlur = () => {
    if (bothEntered) {
      performMajorCheck(formState);
    }
  }

  const handleSecondaryOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    const state = handleForm(e);
    setBothEntered(true);
    performMajorCheck(state);
  }

  const getValid = (): boolean => {
    return passwordsFormatMinChars && passwordsMatch;
  }

  const handleResetPassword = (e: React.FormEvent): void => {
    e.preventDefault()
    if (token) {
      resetPassword(token, formState.primary)
        .then(({ status, data }) => {
          if (status === 200) {
            swal.fire("Success", data.message, "success").then(() => {
              navigate("/");
            });
          }
          else {
            swal.fire("Fail", data.message, "error");
          }
        })
        .catch((data) => {
          handleGenericError(data.message ? data.errorMessage : "unknown error");
        })
    }
  }

  return (
    <>
      <article>
        <form className="reset-password-form" onSubmit={(e) => handleResetPassword(e)}>
          <label htmlFor='password'>New Password</label><br />
          <input type="password" onBlur={handlePrimaryOnBlur} onChange={handlePrimaryOnChange} value={formState.primary} id='primary' /><br />

          <label htmlFor='secondary'>Match Password</label><br />
          <input type="password" onBlur={handleSecondaryOnBlur} onChange={handleSecondaryOnChange} value={formState.secondary} id='secondary' /><br />
          <ul className='no-points'>
            <li>
              {
                passwordsFormatMinChars ?
                  <img className="reset-password-icon" src="/img/icons/tick-green-small.svg" alt="Yes" /> :
                  <img className="reset-password-icon" src="/img/icons/x-small.svg" alt="No" />
              } Minimum Length (8 characters)</li>
            <li>
              {
                passwordsMatch ?
                  <img className="reset-password-icon" src="/img/icons/tick-green-small.svg" alt="Yes" /> :
                  <img className="reset-password-icon" src="/img/icons/x-small.svg" alt="No" />
              } Passwords Match</li>
          </ul>
          <br />
          <button disabled={!getValid()} className='standard-button'>Reset Password</button>
        </form>
      </article>
      <nav className="hide-in-tablet"></nav>
      <aside className="hide-in-tablet"></aside>
    </>
  );
}

export default ResetPassword