export const USER_STORED_SEARCHES = 5;

export enum OrderType {
  IOA = 'ioa',
  EBAY = 'ebay',
}

export enum ItemType {
  COMIC = 'comic',
  GAME = 'game',
  ACCESSORY = 'accessory',
}

export enum PeriodType {
  NEW = 'new',
  BACK_ISSUE = 'back-issue',
  PRE_ORDER = 'pre-order',
  VINTAGE = 'vintage',
}

export enum SupplierType {
  DIAMOND = 'diamond',
  // LUNAR
  LPG = 'lpg',
  AUCTION = 'auction',
  LOCAL_SHOP = 'local_shop',
  LOCAL_PERSON = 'local_person',
}

export enum OldBatchType {
  DIAMOND = 'diamond',
  BACK_ISSUE = 'back',
  PRE = 'pre',
  GAME = 'game',
}

export const blankBatch = (): IBatch => {
  return {
    type: OldBatchType.DIAMOND,
    itemType: ItemType.COMIC,
    periodType: PeriodType.NEW,
    supplierType: SupplierType.DIAMOND,
    name: '',
    publishDate: (new Date().setUTCDate(new Date().getUTCDate() + 21)),
    default: false,
    discountPercent: 0,
  };
}

export const blankComic = (itemType: ItemType, periodType?: PeriodType): IComic => {
  return {
    itemType: itemType,
    periodType: periodType ? periodType : PeriodType.NEW,
    supplierType: SupplierType.DIAMOND,
    publisher: '',
    mainDescription: '',
    variantDescription: '',
    srp: 0,
    comicId: '',
    number: 1,
    pnp: 0,
    batch: blankBatch(),
    releaseMonth: 0, // TODO
    releaseYear: 0, // TODO
    initialQuantity: 1,
    salesQuantity: 0,
    salesValue: 0,
    batchName: 'unknown', // TODO
    images: [],
  };
}

export const copyComic = (copyComic: IComic, itemType: ItemType, periodType?: PeriodType): IComic => {
  return {
    ...blankComic(itemType, periodType),
    mainDescription: copyComic.mainDescription,
    variantDescription: copyComic.variantDescription,
    publisher: copyComic.publisher,
    releaseMonth: copyComic.releaseMonth,
    releaseYear: copyComic.releaseYear,
    priceOverride: copyComic.priceOverride,
    pnp: copyComic.pnp,
  };
}

export const createOrderItem = (comic: IComic, price: number): IOrderItem => {
  return {
    name: comic.mainDescription,
    type: comic.type,
    itemType: comic.itemType,
    periodType: comic.periodType,
    supplierType: comic.supplierType,
    available: comic.number,
    quantity: 1,
    price: price,
    comicId: comic.comicId,
    pnp: comic.pnp ? comic.pnp : 0
  }
}

// >> combos
// COMIC.NEW.DIAMOND (NEW)
// COMIC.PRE_ORDER.DIAMOND (PRE)
// COMIC.BACK_ISSUE.AUCTION (BACK)
// COMIC.BACK_ISSUE.LOCAL_SHOP (!)
// COMIC.BACK_ISSUE.LOCAL_PERSON (!)
// GAME.NEW.LPG (GAME)
// GAME.VINTAGE.AUCTION (!)
// ACCESSORY.NEW.LPG (ACCESSORY)


// >> menu options
// COMIC.NEW (NEW)
// COMIC.PRE_ORDER (PRE)
// COMIC.BACK_ISSUE (BACK)
// GAME.NEW (GAME)
// GAME.VINTAGE (!)
// ACCESSORY.NEW (ACCESSORY)

// new methods ------------------------------------------------------

export const getItemTypeName = (itemType: ItemType) => {
  switch (itemType) {
    case ItemType.COMIC: { return 'Comics'; }
    case ItemType.GAME: { return 'Games'; }
    case ItemType.ACCESSORY: { return 'Accessories'; }
  }
}

export const getPeriodTypeName = (periodType: PeriodType) => {
  switch (periodType) {
    case PeriodType.NEW: { return 'New'; }
    case PeriodType.BACK_ISSUE: { return 'Back Issue'; }
    case PeriodType.PRE_ORDER: { return 'Pre-Order'; }
    case PeriodType.VINTAGE: { return 'Vintage'; }
  }
}

export const getSupplierTypeName = (supplierType: SupplierType) => {
  switch (supplierType) {
    case SupplierType.DIAMOND: { return 'Diamond'; }
    case SupplierType.LPG: { return "Let's Play Games"; }
    case SupplierType.AUCTION: { return 'Auction'; }
    case SupplierType.LOCAL_SHOP: { return 'Local Shop'; }
    case SupplierType.LOCAL_PERSON: { return 'Local Person'; }
  }
}

export const getPrimaryTitleFromTypes = (itemType: ItemType, periodType?: PeriodType):string => {
  if (itemType === ItemType.COMIC && periodType) {
    return getPeriodTypeName(periodType) + ' ' + getItemTypeName(itemType);
  }
  return  getItemTypeName(itemType);
}

export const getPublishersByItemType = (itemType: ItemType): string[] => {
  switch (itemType) {
    case ItemType.COMIC: {
      return [
        // major labels
        "Marvel Comics",
        "DC Comics",
        "Image Comics",
        "Dark Horse Comics",
        "IDW Publishing",
        "Boom! Studios",
        // major imprints
        "Epic Marvel Comics",
        "Icon Marvel Comics",
        "Federal Marvel Comics",
        "Marvel Max Comics",
        "Marvel Knights Comics",
        "Federal DC Comics",
        "Vertigo DC Comics",
        "Wildstorm DC Comics",
        "Top Cow Image Comics",
        // others
        "Archie Comics",
        "Blackthorn Publishing",
        "Dynamite Entertainment",
        "Maximum Press Comics",
        "Topps Comics",
        "Valiant Comics",
      ];
    }
    case ItemType.GAME: {
      return [
        'Wizards of the Coast',
        'TSR Wizards of the Coast',
        'Paizo'
      ];
    }
    case ItemType.ACCESSORY: {
      return [
        'Ultimate Guard'
      ];
    }
  }
}


export const getURLFromTypes = (itemType?: ItemType, periodType?: PeriodType) => {
  if (itemType) {
    switch (itemType) {
      case ItemType.COMIC: {
        if (periodType) {
          switch (periodType) {
            case PeriodType.BACK_ISSUE: { return '/back-issues'; }
            case PeriodType.PRE_ORDER: { return '/pre-orders'; }
            case PeriodType.NEW: { return '/new-comics'; }
            default: { return '/'; }
          }
        }
        else {
          return '/';
        }
      }
      case ItemType.GAME: { return '/games'; }
      case ItemType.ACCESSORY: { return '/accessories'; }
      default: { return '/'; }
    }
  }

  return '/';
}

export const getBatchNameForUser = ( batch: IBatch ) => {
  const monthString = batch.publishDate ? ' - ' + new Date(batch.publishDate).toLocaleString('default', { month: 'long' }) : '';
  return getPeriodTypeName(batch.periodType) + ' ' + getItemTypeName(batch.itemType) + monthString; 
}


// old definitions ------------------------------------------------------------------------------

export enum OldComicType {
  NEW = 'new',
  BACK = 'back',
  PRE = 'pre',
  GAME = 'game',
  ACCESSORY = 'accessory',
}

// // converters -------------------------------

// export const getItemType = (ct: OldComicType): ItemType => {
//   switch (ct) {
//     case OldComicType.BACK: { return ItemType.COMIC }
//     case OldComicType.PRE: { return ItemType.COMIC }
//     case OldComicType.GAME: { return ItemType.GAME }
//     case OldComicType.ACCESSORY: { return ItemType.COMIC }
//     default: { return ItemType.COMIC } // OldComicType.NEW
//   }
// }

// export const getPeriodType = (ct: OldComicType): PeriodType => {
//   switch (ct) {
//     case OldComicType.BACK: { return PeriodType.BACK_ISSUE }
//     case OldComicType.PRE: { return PeriodType.NEW }
//     case OldComicType.GAME: { return PeriodType.NEW }
//     case OldComicType.ACCESSORY: { return PeriodType.NEW }
//     default: { return PeriodType.NEW } // OldComicType.NEW
//   }
// }

// export const getSupplierType = (bt: OldBatchType): SupplierType => {
//   switch (bt) {
//     case OldBatchType.BACK_ISSUE: { return SupplierType.AUCTION }
//     case OldBatchType.DIAMOND: { return SupplierType.DIAMOND }
//     case OldBatchType.PRE: { return SupplierType.DIAMOND }
//     default: { return SupplierType.LPG } // OldBatchType.GAME
//   }
// }



// // old methods ----------------------------

// export const getOldComicTypeName = (ct: OldComicType) => {
//   switch (ct) {
//     case OldComicType.BACK: { return 'Back Issues'; }
//     case OldComicType.PRE: { return 'Pre-orders'; }
//     case OldComicType.GAME: { return 'Games'; }
//     case OldComicType.ACCESSORY: { return 'Accessories'; }
//     default: { return 'New Stocked Comics'; }
//   }
// }

// export const getOldBatchTypeFromOldComicType = (ct: OldComicType) => {
//   switch (ct) {
//     case OldComicType.BACK: { return OldBatchType.BACK_ISSUE; }
//     case OldComicType.PRE: { return OldBatchType.PRE; }
//     case OldComicType.GAME: { return OldBatchType.GAME; }
//     case OldComicType.ACCESSORY: { return OldBatchType.GAME; }
//     default: { return OldBatchType.DIAMOND; }
//   }
// }

// export const getOldComicTypeFromOldBatchType = (ct: OldBatchType) => {
//   switch (ct) {
//     case OldBatchType.BACK_ISSUE: { return OldComicType.BACK; }
//     case OldBatchType.PRE: { return OldComicType.PRE; }
//     case OldBatchType.GAME: { return OldComicType.GAME; }
//     case OldBatchType.DIAMOND: { return OldComicType.NEW; }
//     default: { return OldComicType.BACK; }
//   }
// }

// export const getURLFromOldComicType = (ct: OldComicType | undefined) => {
//   switch (ct) {
//     case OldComicType.BACK: { return '/back-issues'; }
//     case OldComicType.PRE: { return '/pre-orders'; }
//     case OldComicType.GAME: { return '/games'; }
//     case OldComicType.ACCESSORY: { return '/accessories'; }
//     default: { return '/'; }
//   }
// }