import React from "react";
import { ItemType, PeriodType } from "../../common";
import BatchDropDown2 from "../admin-stockcontrol/BatchDropDown2";

type Props = {
  batches: IBatch[],
  itemType: ItemType,
  periodType?: PeriodType,
  batchFilter: string,
  setBatchFilter: (batchFilter: string) => void,
}

const BatchFilter: React.FC<Props> = ({ batches, itemType, periodType, batchFilter, setBatchFilter }) => {

  const batchesFiltered = [...new Set<IBatch>(batches?.filter(obj => matchBatchToItemAndPeriod(obj, itemType, periodType)))];
  const batchInList =
    batchesFiltered.length === 0 ? [] :
      batchesFiltered.length >= 2 ? [batchesFiltered[0], batchesFiltered[1]] : [batchesFiltered[0]];
  const batchInDropdown =
    batchesFiltered.length === 0 ? [] :
      batchesFiltered.length >= 3 ? batchesFiltered.slice(2) : [];

  // console.log(JSON.stringify(batchesFiltered));
  // console.log(JSON.stringify(batchInList));
  // console.log(JSON.stringify(batchInDropdown));

  const setBatch = (batch: IBatch | undefined) => {
    if (batch) {
      setBatchFilter(batch.name);
    }
  }

  console.log('batchFilter ' + batchFilter);

  return (
    <span className='link-small'>
      {batchInList && batchInList.length > 0 && batchInList.map(c => (<span key={c.name}>&nbsp;<button onClick={() => setBatchFilter(c.name)} className={'link-button' + (c.name === batchFilter ? ' bold' : '')}>{c.name}</button>&nbsp;•&nbsp;</span>))}
      {batchInDropdown.length >= 1 &&
        <>
          <BatchDropDown2 batches={batchInDropdown} batchName={batchFilter} setBatch={setBatch} />
          &nbsp;•&nbsp;
        </>}
      <button onClick={() => setBatchFilter('all')} className={'link-button' + ('all' === batchFilter ? ' bold' : '')}>All</button>
    </span>)
}

export default BatchFilter;

export const matchBatchToItemAndPeriod = (batch: IBatch, itemType: ItemType, periodType?: PeriodType): boolean => {

  // console.log(batch.name + ' ' + batch.itemType + ' ' + batch.periodType + ' ' + itemType + ' ' + periodType);

  return (batch.itemType === itemType) && (periodType === undefined || (batch.periodType === periodType));
}
