import React, { useEffect, useState } from 'react'
import { ebayStockCheck, getCurrentOrder, updateOrder } from '../../API';
import AdminNavigation from './AdminNavigation'
import { updateOrderState } from '../../utils/OrderCalculations';
import { getAdminFromStorage } from '../../utils/UserHandler';
import { State } from '../../App';
import { getPriceComicB } from '../../utils/MoneyFormats';
import { createOrderItem, OrderType } from '../../common';
import OrderList from '../order/OrderList';

type Props = {
  state: State,
  setState: any,
  libraries: string[],
  batches: IBatch[],
  firstOrderItemRef:any,
  transactions: ITransaction[],
  setTransactions(transactions: ITransaction[]): void,
  credit: number,
  setCredit(credit: number): void,
}

const EbayStockCheck: React.FC<Props> = ({state, setState, libraries, batches, firstOrderItemRef, transactions, setTransactions, credit, setCredit}) => {
  const LOADING = '...loading';
  const [stocks, setStock] = useState<IStockCheck[]>();
  const [message, setMessage] = useState('');
  
  const [order, setOrder] = useState<IOrder>({
    _id: LOADING, type: OrderType.IOA, date: '01/01/2022', complete: false, posted: false, carriedForward: 0, orderItems: [], latestTransaction: undefined,
    searchTerms: '',
    searchHistory: [],
    shippingIsPickup: false, shippingIsSameAsBilling: false,
    toPay: 0, pnp: 0, total: 0, itemCount: 0, paid: 0, creditFrom: 0, creditForward: 0
  });

  useEffect(() => {
    fetchCurrentOrder();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  
  const update = (order: IOrder, transactions: ITransaction[], credit: number) => {

    // transactions = transactions ? transactions : [];
    // setTransactions(transactions);
    // credit = credit ? credit : 0;
    // setCredit(credit);

    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));

    // console.log('transactions' + JSON.stringify(transactions))
    // setTransactions(transactions ? transactions : []);
    updateState(order, transactions, credit);

    addHighlightToFirstOrderItem();
  }
  
  const updateState = (order: IOrder, transactions: ITransaction[], credit: number) => {
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
  }

  const fetchCurrentOrder = () => {
    // console.log(`fetchCurrentOrder()`);
    getCurrentOrder()
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err: Error) => console.log(err));
  }

  const handleStockCheck = () => {
    ebayStockCheck()
      .then(({ data }) => {
        setStock(data.stockCheck)
        setMessage(data.message)
      })
      .catch((err) => console.log(err))
  }

  const addHighlightToFirstOrderItem = (): void => {
    if (firstOrderItemRef && firstOrderItemRef.current) {
      firstOrderItemRef.current.className = 'order-item highlight-order-item';
    }
  }
  
  const handleAddComicButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, comic: IComic): void => {
    e.stopPropagation();
    handleAddComic(comic);
  }

  const handleAddComic = (comic: IComic): void => {
    // console.log(comic);

    const orderItem = order.orderItems.find((i) => i.comicId === comic.comicId);
    if (orderItem && orderItem !== undefined) {
      // update
      order.orderItems.splice(order.orderItems.indexOf(orderItem), 1);
      orderItem.quantity++;

      // add at top of array
      const newOrderItems = [orderItem, ...order.orderItems,];
      order.orderItems = newOrderItems;
    }
    else {
      // console.log(`comic.comicId ` + comic.comicId)
      // add
      const newOrderItems = [
        createOrderItem(comic, getPriceComicB(comic, comic.batch)),
        ...order.orderItems
      ];
      order.orderItems = newOrderItems;
    }
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }

  return (
    <>
      <article>
        <h1>Ebay Stock Check</h1>
        <button className='link-button' onClick={handleStockCheck}>Check Stock</button><br />
        {message
          ? (
            <>
              <div>&nbsp;</div>
              <div>Result : {message} {stocks?.length}</div>
              <div>&nbsp;</div>
              {stocks && stocks.map((stock: IStockCheck) => (
                (<div>{stock.name} ({stock.type}) : {stock.ioaQuantity} / {stock.ebayQuantity} • <button className='link-button' onClick={(e) => handleAddComicButton(e, stock.comic)}>add</button></div>)
              ))}
            </>)
          : (<></>)}
      </article>
      <nav><AdminNavigation /></nav>
      <aside>
      {!state.showMobileOrder &&
        <OrderList
          order={order}
          setOrder={setOrder}
          state={state}
          setState={setState}
          libraries={libraries}
          batches={batches}
          firstOrderItemRef={firstOrderItemRef}
          transactions={transactions}
          setTransactions={setTransactions}
          credit={credit}
          setCredit={setCredit}
          mobile={false}
        />}
      </aside>
    </>
  )
}

export default EbayStockCheck