import React from 'react'
import './ViewEditStockItemSwitcher.scss';
import ViewGenericItem from './ViewGenericItem';

type Props = {
  formComic: IComic,
  setFormComic: (formComic: IComic) => void,
  deleteStock: (formComic: IComic) => void,
  addStock?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, comic: IComic) => void,
  handleEditMode: () => void,
}

const ViewGameItem: React.FC<Props> = ({ formComic, setFormComic, deleteStock, addStock, handleEditMode }) => {

  return (
    <ViewGenericItem
      formComic={formComic} setFormComic={setFormComic} deleteStock={deleteStock} addStock={addStock} handleEditMode={handleEditMode}

      // showSRP={false}
      // showImages={true}
      // showEbayPricing={true}
      showQuantity={true}
      // showMonth={false}
      // showYear={true}
    />);
}

export default ViewGameItem;