import React, { useEffect, useRef, useState } from 'react'
import OrderListComic from './OrderListComic'
import { getCurrentOrder, getStocks, searchStocks, updateOrder } from '../../API'
import './MainFeed.scss';
import { getPriceComicB } from '../../utils/MoneyFormats';
import { updateOrderState } from '../../utils/OrderCalculations';
import { getPeriodTypeName, USER_STORED_SEARCHES, createOrderItem, getItemTypeName, getURLFromTypes, getPrimaryTitleFromTypes, ItemType, PeriodType } from '../../common';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { State } from '../../App';
import { getAdminFromStorage } from '../../utils/UserHandler';
import BatchFilter from '../general/BatchFilter';
import OrderList from './OrderList';

type Props = {
  state: State,
  setState(state: State): void,
  order: IOrder,
  setOrder(order: IOrder): void,
  libraries: string[],
  batches: IBatch[],
  firstOrderItemRef:any,
  transactions: ITransaction[],
  setTransactions(transactions: ITransaction[]): void,
  credit: number,
  setCredit(credit: number): void,
}

const MainFeed: React.FC<Props> = ({ state, setState, order, setOrder, libraries, batches, firstOrderItemRef, transactions, setTransactions, credit, setCredit }) => {
  const articleRef = useRef<HTMLDivElement>(null);
  const [stock, setStock] = useState<IComic[]>([]);
  const [articleScrollTop, setArticleScrollTop] = useState(0);
  const [pagination, setPagination] = useState({
    isLoading: false,
    page: 1,
    lastMode: state.mode,
    lastPage: 0,
    lastFilter: 'all',
    endOfFile: false,
    stockCache: [] as IComic[],
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentOrder();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchStock();
  }, [state.mode, state.searchTerm, pagination.page, state.batch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = () => {
    const articleDiv = articleRef.current;

    if (state.searchTerm === '') {
      // console.log('updating scrolltop ');
      setArticleScrollTop(articleDiv ? articleDiv.scrollTop : 0);
    }

    // Check if scrolled to the bottom of the content div
    if (
      state.searchTerm === '' &&
      articleDiv &&
      !pagination.isLoading &&
      articleDiv.scrollTop + articleDiv.clientHeight + 100 >= articleDiv.scrollHeight
    ) {
      // Trigger the scroll event in the parent component
      setPagination({ ...pagination, page: ++pagination.page, isLoading: true });
    }
  };

  const update = (order: IOrder, transactions: ITransaction[], credit: number) => {

    transactions = transactions ? transactions : [];
    setTransactions(transactions);
    credit = credit ? credit : 0;
    setCredit(credit);

    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));

    // console.log('transactions' + JSON.stringify(transactions))
    // setTransactions(transactions ? transactions : []);
    updateState(order, transactions, credit);

    addHighlightToFirstOrderItem();
  }

  const fetchCurrentOrder = () => {
    // console.log(`fetchCurrentOrder()`);
    getCurrentOrder()
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err: Error) => console.log(err));
  }

  const fetchStock = () => {

    // console.log('fetchStock() ' + batchFilter);

    if (state.searchTerm) {

      setPagination({ ...pagination, isLoading: true, stockCache: stock });

      // legacy
      if (order && !getAdminFromStorage()) {
        order.searchTerms += (order.searchTerms === '' ? state.searchTerm : ' | ' + state.searchTerm);
      }

      if (order) {
        // is term in history
        if (order.searchHistory.indexOf(state.searchTerm) >= 0) {
          // shift to top
          order.searchHistory = order.searchHistory.filter(item => item !== state.searchTerm);
          order.searchHistory.unshift(state.searchTerm);
        }
        else {
          // add at start and delete last entry
          order.searchHistory.unshift(state.searchTerm);
          if (order.searchHistory.length > USER_STORED_SEARCHES) {
            order.searchHistory.pop();
          }
        }

        // console.log(' order.searchHistory = ' + order.searchHistory);

        setOrder(order);
      }

      searchStocks(state.searchTerm)
        .then(({ data: { comics } }: IComic[] | any) => setStock(comics))
        .catch((err: Error) => console.log(err))

      setPagination({ ...pagination, isLoading: false });
      setTimeout(() => {
        if (articleRef && articleRef.current) {
          articleRef.current.scrollTop = 0;
        }
      }, 50);
    }
    else {

      // console.log('cache page = ' + pagination.page + '  lastPage= ' + pagination.lastPage + '  lastFilter=' + pagination.lastFilter);
      if (pagination.page === pagination.lastPage && pagination.lastMode === state.mode && pagination.lastFilter === state.batch) {
        // restore from cache after being on search

        // console.log('reloading cache page');
        setStock(pagination.stockCache);
        setTimeout(() => {
          if (articleRef && articleRef.current) {
            articleRef.current.scrollTop = articleScrollTop;
            // console.log("set scroll good " + articleScrollTop);
          }
          else {
            // console.log("set scroll fail " + articleScrollTop);
          }
        }, 50);
      }
      else {

        if (pagination.lastMode !== state.mode || pagination.lastFilter !== state.batch) {
          // console.log('mode switch: reloading cache');
          pagination.page = 1;
          pagination.endOfFile = false;
        }
        else {
          // console.log('adding to cache');
        }

        // console.log('getStocks() ' + batchFilter);

        console.log("pagination.endOfFile " + pagination.endOfFile);
        if (!pagination.endOfFile) {
          const itemsToGet = (pagination.page === 1 ? 60 : 30);

          getStocks(state.mode.itemType, state.mode.periodType, pagination.page, itemsToGet, state.batch)
            .then(({ data: { comics } }: IComic[] | any) => {
              // console.log('newStock !!');
              const newStockGot = comics.length;

              let newStock = pagination.lastMode === state.mode && pagination.lastFilter === state.batch ? [...pagination.stockCache, ...comics] : comics;
              // console.log('newStock = ' + newStock.length);
              setStock(newStock);
              setPagination({ ...pagination, isLoading: false, lastPage: pagination.page, lastMode: state.mode, lastFilter: state.batch, stockCache: newStock, endOfFile: newStockGot !== itemsToGet });
              console.log('endOfFile = ' + (newStockGot !== itemsToGet));
            })
            .catch((err: Error) => console.log(err))

          setTimeout(() => {
            if (pagination.lastMode !== state.mode && articleRef && articleRef.current) {
              articleRef.current.scrollTop = 0;
              // console.log("set scroll good " + articleScrollTop);
            }
            else {
              // console.log("set scroll fail " + articleScrollTop);
            }
          }, 50);
        }
      }
    }
  }

  const handleAddComicDiv = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, comic: IComic): void => {
    e.stopPropagation();
    handleAddComic(comic);
  }

  const handleAddComic = (comic: IComic): void => {
    // console.log(comic);

    const orderItem = order.orderItems.find((i) => i.comicId === comic.comicId);
    if (orderItem && orderItem !== undefined) {
      // update
      order.orderItems.splice(order.orderItems.indexOf(orderItem), 1);
      orderItem.quantity++;

      // add at top of array
      const newOrderItems = [orderItem, ...order.orderItems,];
      order.orderItems = newOrderItems;
    }
    else {
      // add
      const price = getPriceComicB(comic, comic.batch);
      //console.log(`comic.comicId ` + comic.comicId + ' price = ' + price)
      const newOrderItems = [
        createOrderItem(comic, price),
        ...order.orderItems
      ];
      order.orderItems = newOrderItems;
    }
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }

  const addHighlightToFirstOrderItem = (): void => {
    if (firstOrderItemRef && firstOrderItemRef.current) {
      firstOrderItemRef.current.className = 'order-item highlight-order-item';
    }
  }

  const updateState = (order: IOrder, transactions: ITransaction[], credit: number) => {
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
  }

  // COPY AND PASTE THIS, BECAUSE REACT IS SHIT
  const handleSearch = (searchTermInput: string) => {
    if (searchTermInput === '') {
      // TODO navigate(state.lastMode === OldComicType.NEW ? '/' : state.lastMode === OldComicType.BACK ? '/back-issues' : '/pre-orders')
      setState({ ...state, searchTerm: searchTermInput, lastMode: undefined });
    }
    else {
      if (!state.lastMode) {
        setState({ ...state, searchTerm: searchTermInput, lastMode: state.mode });
      }
      else {
        setState({ ...state, searchTerm: searchTermInput });
      }
      navigate("/search");
    }
  }

  const clearSearchTerm = () => {
    setState({ ...state, searchTerm: '', lastMode: undefined });
    navigate(getURLFromTypes(state.lastMode?.itemType, state.lastMode?.periodType));
  }

  const setBatchFilter = (batch: string) => {
    setState({
      ...state,
      batch: batch
    });
  }

  const handleNavigationLink = (itemType: ItemType, periodType?: PeriodType) => {
    setState({ ...state, mode: { itemType: itemType, periodType: periodType }, batch: 'all', lastMode: undefined, searchTerm: '', showMobileMenu: false });
    navigate(getURLFromTypes(itemType, periodType));
  }

  // const searchResults = stock && stock.length > 0 && state.searchTerm ? stock.filter(comic => comic.mainDescription.toLocaleLowerCase().indexOf(state.searchTerm.trim().toLocaleLowerCase()) > -1) : [];

  const basicTitle = state.mode.periodType ? getPeriodTypeName(state.mode.periodType) + ' ' + getItemTypeName(state.mode.itemType) : getItemTypeName(state.mode.itemType);
  const title =
    state.searchTerm !== '' ?
      'Search ' + state.searchTerm :
      state.batch !== '' ?
        'Batch earch ' + state.batch :
        basicTitle;

  return (
    <>
      <Helmet>
        <title>Isle of Avalon Comics - {title}</title>
      </Helmet>

      
      <article ref={articleRef} onScroll={handleScroll}>
        <h1>
          {state.searchTerm !== '' ?
            'Search Results for "' + state.searchTerm + '"' :
            state.batch !== '' && getAdminFromStorage() ?
              state.batch : basicTitle}

          {(state.searchTerm === '' && getAdminFromStorage()) &&
            <><BatchFilter batches={batches} itemType={state.mode.itemType} periodType={state.mode.periodType} batchFilter={state.batch} setBatchFilter={setBatchFilter} /></>}
        </h1>
        {state.searchTerm !== '' && (<><h4 className='inline'>{stock.length} results</h4><button className='link-button' onClick={clearSearchTerm}>clear</button></>)}
        {state.batch !== 'all' && (<><button className='link-button' onClick={() => handleNavigationLink(state.mode.itemType, state.mode.periodType)}>return to {getPrimaryTitleFromTypes(state.mode.itemType, state.mode.periodType)}</button></>)}
        <div className='comic-list'>
          {stock !== undefined ?
            stock.map((comicItem) => (
              <OrderListComic
                key={stock.indexOf(comicItem)}
                orderItems={order.orderItems}
                comic={comicItem}
                addComic={handleAddComicDiv}
                handleSearch={handleSearch}
              />
            ))
            :
            <div>no comic items</div>
          }
          {/*pagination.isLoading && <div className='loading-banner'><b>Loading...</b></div>*/}
        </div>
      </article>
      <aside>
        {!state.showMobileOrder &&
          <OrderList
            order={order}
            setOrder={setOrder}
            state={state}
            setState={setState}
            libraries={libraries}
            batches={batches}
            firstOrderItemRef={firstOrderItemRef}
            transactions={transactions}
            setTransactions={setTransactions}
            credit={credit}
            setCredit={setCredit}
            mobile={false}
          />
        }
      </aside>
    </>
  )
}

export default MainFeed
