import React, { useState } from 'react'
import { getUserFromStorage } from '../../utils/UserHandler'
import { addFeedback } from '../../API'
import Swal from 'sweetalert2'
import { handleGenericError } from '../../utils/ErrorHandler'

type Props = {
  handleCloseFeedbackDialog: () => void
}

const FeedbackDialog: React.FC<Props> = ({ handleCloseFeedbackDialog }) => {

  const [formData, setFormData] = useState<IFeedback>({
    _id: '',
    email: getUserFromStorage().email,
    description: '',
  })

  const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  const handleAreaForm = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    setFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    })
  }

  const handleLogin = (e: React.FormEvent): void => {
    e.preventDefault()
    addFeedback(formData)
      .then(({ status, data }) => {
        if (status === 201) {
          Swal.fire("Success", data.message, "success").then(() => {
            handleCloseFeedbackDialog();
          });
        }
        else {
          Swal.fire("Fail", data.message, "error");
        }
      })
      .catch((data) => {
        handleGenericError(data.message ? data.errorMessage : "unknown error");
      })
  }

  return (
    <>
      <div className='grey-background'></div>
      <div className="modal-flex">
        <div className='dialog'>
          <button
            onClick={() => handleCloseFeedbackDialog()}
            className='image-button dialog-close-button'
          >
            <img src='/img/icons/x.svg' alt='delete' />
          </button>
          <h3>Feedback</h3>

          <form className='Form' onSubmit={(e) => handleLogin(e)}>
            {getUserFromStorage()._id === '' ?
              (<div>
                <label htmlFor='email' className='block'>Your Email</label>
                <input onChange={handleForm} type='text' id='email' />
              </div>) : ''}
            <div>
              <label htmlFor='description' className='block'>Your Feedback</label>
              <textarea onChange={handleAreaForm} id='description' />
            </div>
            <br />
            <div className='payment-dialog-button-container'>
              <button className='standard-button'>
                Send Feedback
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default FeedbackDialog
