import React from 'react'
import { formatMoney } from '../../utils/MoneyFormats'
import { AUSPOST_URL } from '../../utils/UrlConsts'
import './AccountOrderHistoryItem.scss';
import { getOrderItemUrl } from '../../utils/ImageHandler';
import { ItemType } from '../../common';
import AdminSearchStockDetails from '../admin/AdminSearchStockDetails';
import { State } from '../../App';
import { updateOrder } from '../../API';
import { updateOrderState } from '../../utils/OrderCalculations';
import { getAdminFromStorage } from '../../utils/UserHandler';

type Props = {
  state: State,
  setState(state: State): void,
  order: IOrder,
  setOrder(order: IOrder): void,
  libraries: string[],
  batches: IBatch[],
  firstOrderItemRef: any,
  // transactions: ITransaction[],
  setTransactions(transactions: ITransaction[]): void,
  // credit: number,
  setCredit(credit: number): void,
  adminInfo?: boolean
}

const AccountOrderHistoryItem: React.FC<Props> = ({ state, setState, order, setOrder, firstOrderItemRef, setTransactions, setCredit, adminInfo }) => {

  const handleOpenPaymentDialog = () => {
    setState({ ...state, showPaymentDialog: true })
  }

  const increaseOrderItem = (orderItem: IOrderItem): void => {
    orderItem.quantity++;
    if (order) {
      // console.log(orderItem.name);
      updateOrder(order)
        .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
          update(order, transactions, credit);
        })
        .catch((err) => console.log(err))
    }
  }

  const decreaseOrderItem = (orderItem: IOrderItem): void => {
    orderItem.quantity--;
    // console.log(orderItem.name);

    if (order) {
      if (orderItem.quantity < 1) {
        // TODO should be done on server, but doing here for now
        order.orderItems = order.orderItems.filter((thisOrderItem: IOrderItem) => thisOrderItem.name !== orderItem.name);
      }
      updateOrder(order)
        .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
          update(order, transactions, credit);
        })
        .catch((err) => console.log(err))
    }
  }

  const update = (order: IOrder, transactions: ITransaction[], credit: number) => {

    transactions = transactions ? transactions : [];
    setTransactions(transactions);
    credit = credit ? credit : 0;
    setCredit(credit);

    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));

    // console.log('transactions' + JSON.stringify(transactions))
    // setTransactions(transactions ? transactions : []);
    updateState(order, transactions, credit);

    addHighlightToFirstOrderItem();
  }

  const addHighlightToFirstOrderItem = (): void => {
    if (firstOrderItemRef && firstOrderItemRef.current) {
      firstOrderItemRef.current.className = 'order-item highlight-order-item';
    }
  }

  const updateState = (order: IOrder, transactions: ITransaction[], credit: number) => {
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
  }

  return (
    <div className='Card'>
      <div className='Card--text'>
        {order &&
          (
            <>
              <h3 className='margin-bottom-less'>
                {order.name ? order.name + ' • ' : ''}
                {order.complete ?
                  <>Order Completed on {order.completedDate ? new Date(order.completedDate).toLocaleString('en-AU', { timeZone: "Australia/Sydney" }) : ''}</> :
                  <>Current Order</>}
              </h3>
              {!order.complete &&
                <>
                  <button
                    onClick={handleOpenPaymentDialog ? () => handleOpenPaymentDialog() : () => { }}
                    className='standard-button'
                    disabled={!(order.orderItems !== undefined && order.orderItems.length > 0)}
                  >
                    Proceed to Payment
                  </button>
                  <br /><br />
                </>}
              {order.posted && order.postedDate && (<div className='margin-bottom-less'>Posted: {new Date(order.postedDate).toLocaleString('en-AU', { timeZone: "Australia/Sydney" })}
                {order.trackingNumber && <span>&nbsp;&nbsp;•&nbsp;&nbsp;<a href={AUSPOST_URL + order.trackingNumber} target='_blank' rel="noreferrer">{order.trackingNumber}</a></span>}</div>)}

              {adminInfo && <AdminSearchStockDetails order={order} />}

              {order.orderItems && order.orderItems.map((orderItem: IOrderItem) => (
                <div className="order-items">
                  <div className="order-item-preview-holder">
                    <img className={orderItem.itemType && orderItem.itemType === ItemType.GAME ? 'comic-item-preview-cover-game' : 'comic-item-preview-cover'} alt='cover' src={getOrderItemUrl(orderItem)} />
                  </div>
                  <div className="order-item-details">
                    <b>{orderItem.name}</b><br/><br/>

                    ${formatMoney(orderItem.price)} ea.<br/>
                    {orderItem.quantity}x {orderItem.available > 1 ?
                    (<span className='two-down'>
                      <button
                        onClick={() => increaseOrderItem(orderItem)}
                        className='plus-button'
                        disabled={!(orderItem.available - orderItem.quantity > 0)}
                      >
                        <span className='plus-text'>
                          +
                        </span>
                      </button>
                      <button
                        onClick={() => decreaseOrderItem(orderItem)}
                        className='minus-button'
                      >
                        <span className='minus-text'>
                          -
                        </span>
                      </button>
                    </span>) : ''} <br/>
                  </div>
                </div>
              ))}
              <br />
              Item Total: ${formatMoney(order.total)}<br />
              Postage: ${formatMoney(order.pnp)}<br />
              Total Paid: ${formatMoney(order.toPay)}<br />
              {order.carriedForward > 0.01 ? (<>Carried Forward: {order.carriedForward}<br /></>) : ""}

            </>)}
      </div>
      <hr />
    </div>
  )
}

export default AccountOrderHistoryItem