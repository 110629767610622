import React from 'react'
import { Link } from 'react-router-dom'
import './AccountNavigation.scss'

const AccountNavigation: React.FC = () => {

  return (
    <div className='account-navigation'>
      <div className='account-title'>ORDERS</div>
      <Link className='account-link block' to="/account-orders">Orders</Link>
    </div>
  )
}

export default AccountNavigation