import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import AdminNavigation from '../admin/AdminNavigation'
import { getSiteSettingsAdmin, saveSiteSettings } from '../../API'
import './SiteSettings.scss'

type Props = {
  siteSettings: ISiteSettings,
  setSiteSettings: (siteSettings: ISiteSettings) => void,
}

const SiteSettings: React.FC<Props> = ({ siteSettings, setSiteSettings }) => {

  useEffect(() => {
    getSiteSettingsAdmin().then(({ data: { siteSettings } }) => {
      console.log('loading site settings');
      console.log('siteSettings ' + JSON.stringify(siteSettings));
      setSiteSettings(siteSettings);
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

    
  const submit = () => {
    saveSiteSettings(siteSettings).then(({status, data}) => {
      if (status === 200) {
        setSiteSettings(data.siteSettings);
      }
    })
  }

  const handleFormBoolean = (e: React.FormEvent<HTMLInputElement>): void => {
    setSiteSettings({
      ...siteSettings,
      [e.currentTarget.id]: e.currentTarget.checked,
    })
  }
  
  const handleFormText = (e: React.FormEvent<HTMLInputElement>): void => {
    setSiteSettings({
      ...siteSettings,
      [e.currentTarget.id]: e.currentTarget.value,
    })
    console.log( )
  }

  return (
    <>
      <Helmet>
        <title>Isle of Avalon Comics - Admin</title>
      </Helmet>
      <article>
        <div className="site-settings">
          <h1>Site Settings</h1>
          <input type="checkbox" checked={siteSettings.hideGames} id='hideGames' onChange={handleFormBoolean} /><label htmlFor="hideGames">Hide Games</label><br/>
          <input type="checkbox" checked={siteSettings.hideAccessories} id='hideAccessories' onChange={handleFormBoolean} /><label htmlFor="hideAccessories">Hide Accessories</label>
          <input type="text" value={siteSettings.warningText} id='warningText' onChange={handleFormText} placeholder='Warning Text'/>
          <input type="checkbox" checked={siteSettings.updateTransactionsSave} id='updateTransactionsSave' onChange={handleFormBoolean} /><label htmlFor="updateTransactionsSave">Update Transaction Save</label>
          <br/>
          <input type="number" value={siteSettings.updateTransactionsDays} id='updateTransactionsDays' onChange={handleFormText} placeholder='Days' className='site-settings-days'/> <label htmlFor="updateTransactionsDays">Days</label>
        </div>
        <div>
          <button onClick={() => submit()}>Save</button>
        </div>
      </article>
      <nav><AdminNavigation /></nav>
      <aside></aside>
    </>);
}

export default SiteSettings;