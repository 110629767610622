import React, { useEffect, useState } from 'react'

type Props = {
  batches: IBatch[] | undefined,
  batchName?: string,
  setBatch: (batch: IBatch | undefined) => void,
}

export const getBatchNameWithType = (batch: IBatch) => {
  return batch.name + ' (' + batch.type + ')';
}

const BatchDropDown: React.FC<Props> = ({ batches, batchName, setBatch }) => {

  const [batchInt, setBatchInt] = useState<IBatch | undefined>();

  useEffect(() => {
    const found = batches?.find(batch => (batch.name === batchName));
    console.log('setBatchInt ' + found );
    setBatchInt(found);
  }, [batchName, batches]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    console.log('e.currentTarget.value = ' + e.currentTarget.value);
    const newBatch = batches?.find(batch => (batch.name === e.currentTarget.value || getBatchNameWithType(batch) === e.currentTarget.value));
    console.log('batch = ' + newBatch);
    setBatch(newBatch);
    setBatchInt(newBatch);
  }

  return (
    <>
      <select className="inline auto-width thin-padding no-margin one-up" value={batchInt === undefined ? '' : batchInt?.name} onChange={handleChange}>
        {batchInt === undefined && <option>...</option>}
        {batches && batches.map((batch, key) => <option key={key}>{batch.name}</option>)}
      </select>
    </>
  )
}

export default BatchDropDown