import React from 'react'

type Props = {
  order: IEbayOrder
}

const CheckListItem: React.FC<Props> = ({ order }) => {

  return (
    <>
      <div className='Card'>
        <div className='Card--text'>
          <span >{order.date} {order.orderNumber}</span>
        </div>
        <div className='Card--text'>
          <span >{order.buyerUsername} {order.buyerName}</span>
        </div>
      </div>
    </>
  )
}

export default CheckListItem
