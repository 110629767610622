import React from 'react';
import AddGenericItem from './AddGenericItem';
import { ItemType, OldBatchType } from '../../common';

type Props = {
  formComic: IComic,
  setFormComic: (formComic: IComic) => void,
  addNewItem: boolean,
  batches: IBatch[] | undefined,
  handleCancel: () => void,
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
}

const AddAccessoryItem: React.FC<Props> = ({ formComic, setFormComic, addNewItem, batches, handleCancel, handleFormSubmit }) => {

  return (
    <AddGenericItem
      formComic={formComic} setFormComic={setFormComic} batches={batches} handleCancel={handleCancel} handleFormSubmit={handleFormSubmit}

      itemType={ItemType.ACCESSORY}
      batchType={OldBatchType.GAME}
      addNewItem={addNewItem}
      showSRP={false}
      showImages={true}
      showEbayPricing={false}
      showMonth={false}
      showYear={false}
      showQuantity={true}
      showSold={!addNewItem}
      showInitialQuantity={true}
    />
  );
}

export default AddAccessoryItem