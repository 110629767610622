import axios, { AxiosResponse } from 'axios'
import { ItemType, PeriodType } from './common';
import { getErrorMessage } from './utils/ErrorHandler';
import { clearUserInStorage, getTempToken } from './utils/UserHandler';
import { ICreditNote } from './components/general/CreditDialog';

const baseUrl: string = process.env.REACT_APP_SERVER_URL || 'http://isleofavalon.com.au/api'; // 'http://localhost:4000' 

// USERs


export const getSiteSettings = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const users: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/site-settings'
    )
    return users
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getSiteSettingsAdmin = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;

    const users: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/site-settings-admin',
      { headers: { Authorization: authStr } },
    )
    return users
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const saveSiteSettings = async (
  formData: ISiteSettings
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;

    const siteSettings: Omit<ISiteSettings, '_id'> = {
      ...formData,
    }
    // console.log('adding comic');
    // console.log(comic);
    const saveSiteSettings: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/save-site-settings',
      siteSettings,
      { headers: { Authorization: authStr } },
    )
    return saveSiteSettings
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}



export const getUsers = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const users: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/users'
    )
    return users
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const userSendCredit = async (creditNote: ICreditNote): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const users: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/user-send-credit',
      creditNote,
      { headers: { Authorization: authStr } },
    )
    return users
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

// LOGIN

export const signUpUser = async (
  formData: ISignUp
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const signUp: Omit<ISignUp, '_id'> = {
      givenName: formData.givenName,
      familyName: formData.familyName,
      email: formData.email,
      password: formData.password,
      passwordConfirm: formData.passwordConfirm,
      tempToken: formData.tempToken,
    }
    const saveOrder: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/sign-up-user',
      signUp
    )
    return saveOrder
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const login = async (
  formData: ILogin
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const login: Omit<ILogin, '_id'> = {
      email: formData.email,
      password: formData.password,
      tempToken: formData.tempToken,
    }
    const loginCall: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/login',
      login
    )
    return loginCall
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const resetPasswordRequest = async (
  email: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const loginCall: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/get-reset-password-token',
      {email: email}
    )
    return loginCall
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const resetPassword = async (
  resetPasswordToken: string, newPassword: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const loginCall: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/reset-password',
      {
        resetPasswordToken: resetPasswordToken,
        newPassword: newPassword,
      }
    )
    return loginCall
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}
// ORDERs

export const getCurrentOrder = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    if (userToken && userToken !== undefined && userToken !== '') {
      const authStr = 'Bearer ' + userToken;
      const currentOrder: AxiosResponse<ApiDataType> = await axios.get(
        baseUrl + '/current-order',
        { headers: { Authorization: authStr } },
      )
      return currentOrder;
    }
    else {
      let tempToken = getTempToken();
      // const authStr = 'Bearer ' + tempToken;
      const currentOrder: AxiosResponse<ApiDataType> = await axios.post(
        baseUrl + '/current-order',
        { tempAuthorisation: tempToken },
      )
      return currentOrder
    }
  } catch (error: any) {
    if (error.response.status === 401) {
      // weird login bug
      clearUserInStorage();
      window.location.reload();
    }
    throw new Error(getErrorMessage(error))
  }
}

export const getOrder = async (_id: string): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const orderItem: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/order/' + _id
    )
    return orderItem
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getOrders = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    // console.log('baseUrl = ' + baseUrl);
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const orders: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/orders',
      { headers: { Authorization: authStr } },
    )
    return orders
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}


export const getOrderAwaitingPostage = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    // console.log('baseUrl = ' + baseUrl);
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const orders: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/orders-awaiting-postage',
      { headers: { Authorization: authStr } },
    )
    return orders
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}


export const getCompleteOrders = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    // console.log('baseUrl = ' + baseUrl);
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const orders: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/complete-orders',
      { headers: { Authorization: authStr } },
    )
    return orders
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}


export const getAdhocOrders = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    // console.log('baseUrl = ' + baseUrl);
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const orders: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/adhoc-orders',
      { headers: { Authorization: authStr } },
    )
    return orders
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getAllOrders = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    // console.log('baseUrl = ' + baseUrl);
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const orders: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/all-orders',
      { headers: { Authorization: authStr } },
    )
    return orders
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const deleteOrder = async (
  _id: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const deletedOrder: AxiosResponse<ApiDataType> = await axios.delete(
      `${baseUrl}/delete-order/${_id}`,
      { headers: { Authorization: authStr } },
    )
    return deletedOrder
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const postOrder = async (
  orderId: string, trackingNumber?: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const updatedCheckList: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/post-order/` + orderId + (trackingNumber ? `/` + trackingNumber : ''),
      { headers: { Authorization: authStr } },
    )
    return updatedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const markAsPosted = async (
  orderId: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const updatedCheckList: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/mark-as-posted/` + orderId,
      { headers: { Authorization: authStr } },
    )
    return updatedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const resendConfirm = async (
  orderId: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const updatedCheckList: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/order-resend-confirm/` + orderId,
      { headers: { Authorization: authStr } },
    )
    return updatedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const resendPosted = async (
  orderId: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const updatedCheckList: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/order-resend-posted/` + orderId,
      { headers: { Authorization: authStr } },
    )
    return updatedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const updateOrder = async (
  order: IOrder
): Promise<AxiosResponse<ApiDataType>> => {
  // console.log(`updateOrder api call ` +order.date );
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;

    let tempToken = userToken ? '' : getTempToken();

    const orderUpdate: Omit<IOrder, '_id'> = {
      ...order
    }
    // console.log(`updateOrder orderUpdate ` +JSON.stringify(orderUpdate));
    const updatedOrder: AxiosResponse<ApiDataType> = await axios.put(
      `${baseUrl}/update-order/${order._id}`,
      { ...orderUpdate, tempAuthorisation: tempToken },
      { headers: { Authorization: authStr } },
    )
    return updatedOrder
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

// TODO!
export const completeOrder = async (
  order: IOrder
): Promise<AxiosResponse<ApiDataType>> => {
  // console.log(`completeOrder api call ` +order.date );
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const orderUpdate: Omit<IOrder, '_id'> = {
      ...order
    }
    // console.log(`processPaymentOnOrder orderUpdate ` +JSON.stringify(orderUpdate));
    const updatedOrder: AxiosResponse<ApiDataType> = await axios.put(
      `${baseUrl}/complete-order/${order._id}`,
      orderUpdate,
      {
        headers: { Authorization: authStr },
        validateStatus: function (status) {
          return status < 500; // Resolve when the status code is less than 500
        }
      }
    )
    return updatedOrder
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

// TRANSACTIONS

export const createTransaction = async (
  order: IOrder
): Promise<AxiosResponse<ApiDataType>> => {
  // console.log(`updateOrder api call ` +order.date );
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const orderUpdate: Omit<IOrder, '_id'> = {
      ...order
    }
    // console.log(`updateOrder orderUpdate ` +JSON.stringify(orderUpdate));
    const updatedOrder: AxiosResponse<ApiDataType> = await axios.put(
      `${baseUrl}/update-order/${order._id}`,
      orderUpdate,
      { headers: { Authorization: authStr } },
    )
    return updatedOrder
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

// AUDIT HISTORY

export const getAuditHistory = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const apiCall: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/audit-history'
    )
    return apiCall
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

// STOCKs

export const getStocks = async (itemType?: ItemType, periodType?: PeriodType, page?: number, limit?: number, batchId?: string): Promise<AxiosResponse<ApiDataType>> => {

  const key = (itemType === ItemType.COMIC && periodType === undefined);
  console.log('getStock itemType   ' + itemType);
  console.log('getStock periodType ' + periodType);
  console.log('getStock batch ' + batchId);
  console.log('getStock limit ' + limit);
  console.log('getStock page  ' + page);
  console.log('getStock key   ' + key);

  try {
    const url = baseUrl + `/stock/`;
  
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const stocks: AxiosResponse<ApiDataType> = await axios.put(
      url,
      { itemType, periodType, batchId, limit, page, key },
      { headers: { Authorization: authStr } }
    );
    return stocks
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}


export const getStocksAdmin = async (itemType?: ItemType, periodType?: PeriodType, page?: number, limit?: number, batchId?: string): Promise<AxiosResponse<ApiDataType>> => {

  const zero: number = batchId === 'all' ? 1 : 2;
  console.log('getStock mode  ' + itemType);
  console.log('getStock mode  ' + periodType);
  console.log('getStock batch ' + batchId);
  console.log('getStock limit ' + limit);
  console.log('getStock page ' + page);
  console.log('getStock zero ' + zero);

  try {
    const url = baseUrl + `/admin-stock/`;
  
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const stocks: AxiosResponse<ApiDataType> = await axios.put(
      url,
      { itemType, periodType, batchId, limit, page, zero },
      { headers: { Authorization: authStr } }
    );
    return stocks
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const homepageStocks = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
      const currentOrder: AxiosResponse<ApiDataType> = await axios.get(
        baseUrl + `/homepage-stock`
      )
      return currentOrder;    
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getStocksForPre = async (batchId?: string, limit?: number): Promise<AxiosResponse<ApiDataType>> => {

  try {
    const url = baseUrl + `/stock-for-pre/` + batchId + (limit ? '/' + limit : '');
    const stocks: AxiosResponse<ApiDataType> = await axios.get(url);
    return stocks
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getZeroStocks = async (limit?: number): Promise<AxiosResponse<ApiDataType>> => {

  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const url = baseUrl + `/zero-stock/` + (limit ? limit : '');
    console.log(url);
    const stocks: AxiosResponse<ApiDataType> = await axios.get(url, { headers: { Authorization: authStr } });
    return stocks
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const searchStocks = async (searchTerm: string): Promise<AxiosResponse<ApiDataType>> => {
  try {
    searchTerm = encodeURIComponent(searchTerm);

    console.log('searchTerm ' + searchTerm);

    const userToken = localStorage.getItem('accessToken');
    if (userToken && userToken !== undefined && userToken !== '') {
      const authStr = 'Bearer ' + userToken;
      const currentOrder: AxiosResponse<ApiDataType> = await axios.get(
        baseUrl + `/search-stock/` + searchTerm,
        { headers: { Authorization: authStr } },
      )
      return currentOrder;
    }
    else {
      let tempToken = getTempToken();
      // const authStr = 'Bearer ' + tempToken;
      const currentOrder: AxiosResponse<ApiDataType> = await axios.post(
        baseUrl + `/search-stock/` + searchTerm,
        { tempAuthorisation: tempToken },
      )
      return currentOrder
    }
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const searchStocksAdmin = async (searchTerm: string): Promise<AxiosResponse<ApiDataType>> => {
  try {
    searchTerm = encodeURIComponent(searchTerm);

    console.log('searchTerm ' + searchTerm);

    const userToken = localStorage.getItem('accessToken');
    if (userToken && userToken !== undefined && userToken !== '') {
      const authStr = 'Bearer ' + userToken;
      const currentOrder: AxiosResponse<ApiDataType> = await axios.get(
        baseUrl + `/admin-search-stock/` + searchTerm,
        { headers: { Authorization: authStr } },
      )
      return currentOrder;
    }
    throw new Error("needs user");
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const addStock = async (
  formData: IComic
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;

    const comic: Omit<IComic, '_id'> = {
      ...formData
    }
    // console.log('adding comic');
    // console.log(comic);
    const saveCheckList: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/add-stock',
      comic,
      { headers: { Authorization: authStr } },
    )
    return saveCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const updateStock = async (
  comic: IComic
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const comicUpdate: Omit<IComic, '_id'> = {
      ...comic,
    }
    const updatedCheckList: AxiosResponse<ApiDataType> = await axios.put(
      `${baseUrl}/edit-stock/${comic._id}`,
      comicUpdate,
      { headers: { Authorization: authStr } },
    )
    return updatedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const checkImages = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;

    const updatedCheckList: AxiosResponse<ApiDataType> = await axios.put(
      `${baseUrl}/check-images`,
      { headers: { Authorization: authStr } },
    )
    return updatedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const deleteStock = async (
  _id: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const deletedStock: AxiosResponse<ApiDataType> = await axios.delete(
      `${baseUrl}/delete-stock/${_id}`,
      { headers: { Authorization: authStr } },
    )
    return deletedStock
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

// BATCH

export const getBatches = async (): Promise<AxiosResponse<ApiDataType>> => {

  try {
    const url = baseUrl + `/all-batches`;
    // console.log(url);
    const batches: AxiosResponse<ApiDataType> = await axios.get(url);
    return batches
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const addBatch = async (
  formData: IBatch
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;

    const batch: Omit<IBatch, '_id'> = {
      ...formData
    }
    // console.log('adding batch');
    // console.log(batch);
    const saveCheckList: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/add-batch',
      batch,
      { headers: { Authorization: authStr } },
    )
    return saveCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const updateBatch = async (
  batch: IBatch,
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const batchUpdate: Omit<IBatch, '_id'> = {
      ...batch
    }
    const updatedCheckList: AxiosResponse<ApiDataType> = await axios.put(
      `${baseUrl}/edit-batch/${batch._id}`,
      batchUpdate,
      { headers: { Authorization: authStr } },
    )
    return updatedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const deleteBatch = async (
  _id: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const deletedStock: AxiosResponse<ApiDataType> = await axios.delete(
      `${baseUrl}/delete-batch/${_id}`,
      { headers: { Authorization: authStr } },
    )
    return deletedStock
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const clearStock = async (
  batch: IBatch
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    
    console.log('batchid ' + batch._id);    
    console.log('batch.name ' + batch.name);

    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const clearStock: AxiosResponse<ApiDataType> = await axios.delete(
      `${baseUrl}/clear-stock/${batch._id}`,
      { headers: { Authorization: authStr } },
    )
    return clearStock
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

// FEEDBACKs

export const getFeedback = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const feedbacks: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/feedback'
    )
    return feedbacks
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const addFeedback = async (
  formData: IFeedback
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const apiCall: Omit<IFeedback, '_id'> = {
      email: formData.email,
      description: formData.description,
    }
    const saveCheckList: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/add-feedback',
      apiCall
    )
    return saveCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getOrdersProcessed = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const apiCall: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/admin-orders-processed',
      { headers: { Authorization: authStr } },
    )
    return apiCall
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

// TODOs

export const getCheckListItems = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const apiCall: AxiosResponse<ApiDataType> = await axios.get(
      baseUrl + '/admin-check-list',
      { headers: { Authorization: authStr } },
    )
    return apiCall
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getCheckListDeleteAll = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const apiCall: AxiosResponse<ApiDataType> = await axios.delete(
      baseUrl + '/admin-check-list-delete-all',
      { headers: { Authorization: authStr } },
    )
    return apiCall
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const addCheckListItem = async (
  formData: ICheckList
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const apiCall: Omit<ICheckList, '_id'> = {
      name: formData.name,
      description: formData.description,
      status: false,
    }
    const saveCheckList: AxiosResponse<ApiDataType> = await axios.post(
      baseUrl + '/add-check-list-item',
      apiCall,
      { headers: { Authorization: authStr } },
    )
    return saveCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const updateCheckList = async (
  checkListItem: ICheckList
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const checkListUpdate: Pick<ICheckList, 'status'> = {
      status: true,
    }
    const updatedCheckList: AxiosResponse<ApiDataType> = await axios.put(
      `${baseUrl}/edit-check-list-item/${checkListItem._id}`,
      checkListUpdate,
      { headers: { Authorization: authStr } },
    )
    return updatedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const deleteCheckList = async (
  _id: string
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const deletedCheckList: AxiosResponse<ApiDataType> = await axios.delete(
      `${baseUrl}/delete-check-list-item/${_id}`,
      { headers: { Authorization: authStr } },
    )
    return deletedCheckList
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getStatus = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const getStatus: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/status`
    )
    return getStatus
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbaySearch = async (code: string): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const getStatus: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-search/` + encodeURIComponent(code),
      { headers: { Authorization: authStr } },
    )
    return getStatus
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayList = async (code: string): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const getStatus: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-list/` + encodeURIComponent(code),
      { headers: { Authorization: authStr } },
    )
    return getStatus
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayUploadComic = async (comic: IComic): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-upload-comic/` + comic._id,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayUploadBatch = async (batchName: string, batchQuantity: number): Promise<AxiosResponse<ApiDataType>> => {
  console.log('batchName = ' + batchName);
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const url =  `${baseUrl}/ebay-upload-batch/` + encodeURIComponent(batchName) + '/' + batchQuantity;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      url,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayRefreshBatch = async (batchName: string, batchQuantity: number): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-refresh-batch/` + batchName + '/' + batchQuantity,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayUpdateTransactions = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-update-transactions`,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayUpdateTransactionsDontSave = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-update-transactions-test`,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayRefreshComic = async (comic: IComic): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-refresh-comic/` + comic._id,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayClearComic = async (comic: IComic): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-clear-comic/` + comic._id,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const getEbayDeleteComic = async (comic: IComic): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    const authStr = 'Bearer ' + userToken;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-delete-comic/` + comic._id,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const ebayStockCheck = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {

    const userToken = localStorage.getItem('accessToken');
    console.log(userToken);
    const authStr = 'Bearer ' + userToken;
    const result: AxiosResponse<ApiDataType> = await axios.get(
      `${baseUrl}/ebay-stock-check/`,
      { headers: { Authorization: authStr } },
    )
    return result;
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const dataTidy = async (): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    if (userToken && userToken !== undefined && userToken !== '') {
      const authStr = 'Bearer ' + userToken;
      const message: AxiosResponse<ApiDataType> = await axios.get(
        `${baseUrl}/data-tidy`,
        { headers: { Authorization: authStr } },
      )
      return message;
    }
    return getStatus();
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}

export const addStockCheck = async (comic:IComic): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    if (userToken && userToken !== undefined && userToken !== '') {
      const authStr = 'Bearer ' + userToken;
      const comicId = comic.comicId;
      const mainDescription = comic.mainDescription;
      const savedId = comic._id;
      let images = comic.images.map((i) => i.imageId);
      const result: AxiosResponse<ApiDataType> = await axios.put(
        `${baseUrl}/add-stock-check`,
        {
          savedId: savedId,
          comicId: comicId,
          mainDescription: mainDescription,
          images: images,
        },
        { headers: { Authorization: authStr } },
      )
      return result;
    }
    return getStatus();
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}


export const addEbaySales = async ( order: IOrder, ebaySales: IEbaySale[] ): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const userToken = localStorage.getItem('accessToken');
    if (userToken && userToken !== undefined && userToken !== '') {
      const authStr = 'Bearer ' + userToken;
      const result: AxiosResponse<ApiDataType> = await axios.put(
        `${baseUrl}/add-ebay-sales`,
        {
          order: order,
          ebaySales: ebaySales, 
        },
        { headers: { Authorization: authStr } },
      )
      return result;
    }
    return getStatus();
  } catch (error) {
    throw new Error(getErrorMessage(error))
  }
}
