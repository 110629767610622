import React, { useEffect } from 'react';
import { ChangeEvent, useRef, useState } from 'react';
import AdminNavigation from '../admin/AdminNavigation';
import { addEbaySales, getCurrentOrder, updateOrder } from '../../API';
import { createOrderItem, OrderType } from '../../common';
import { getPriceComicB } from '../../utils/MoneyFormats';
import { updateOrderState } from '../../utils/OrderCalculations';
import { getAdminFromStorage } from '../../utils/UserHandler';
import { State } from '../../App';
import * as Papa from 'papaparse';
import OrderList from '../order/OrderList';
import { formatDate } from '../../utils/DateFormat';

type Props = {
  state: State,
  setState: any,
  libraries: string[],
  batches: IBatch[],
  firstOrderItemRef:any,
  transactions: ITransaction[],
  setTransactions(transactions: ITransaction[]): void,
  credit: number,
  setCredit(credit: number): void,
}

const EbaySalesUpload: React.FC<Props> = ({ state, setState, libraries, batches, firstOrderItemRef, transactions, setTransactions, credit, setCredit }) => {
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [ebaySales, setEbaySales] = useState<IEbaySale[]>([]);

  // order set
  const LOADING = '...loading';
  const [stocks, setStock] = useState<IComic[]>([]);
  const [message, setMessage] = useState('');
  const [order, setOrder] = useState<IOrder>({
    _id: LOADING, type: OrderType.IOA, date: '01/01/2022', complete: false, posted: false, carriedForward: 0, orderItems: [], latestTransaction: undefined,
    searchTerms: '',
    searchHistory: [],
    shippingIsPickup: false, shippingIsSameAsBilling: false,
    toPay: 0, pnp: 0, total: 0, itemCount: 0, paid: 0, creditFrom: 0, creditForward: 0
  });


  useEffect(() => {
    fetchCurrentOrder();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const update = (order: IOrder, transactions: ITransaction[], credit: number) => {

    // transactions = transactions ? transactions : [];
    // setTransactions(transactions);
    // credit = credit ? credit : 0;
    // setCredit(credit);

    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));

    // console.log('transactions' + JSON.stringify(transactions))
    // setTransactions(transactions ? transactions : []);
    updateState(order, transactions, credit);

    addHighlightToFirstOrderItem();
  }

  const updateState = (order: IOrder, transactions: ITransaction[], credit: number) => {
    setOrder(updateOrderState(order, transactions, credit, getAdminFromStorage()));
  }

  const fetchCurrentOrder = () => {
    // console.log(`fetchCurrentOrder()`);
    getCurrentOrder()
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err: Error) => console.log(err));
  }

  // const handleStockCheck = () => {
  //   ebayStockCheck()
  //     .then(({ data }) => {
  //       setStock(data.stockCheck)
  //       setMessage(data.message)
  //     })
  //     .catch((err) => console.log(err))
  // }

  const addHighlightToFirstOrderItem = (): void => {
    if (firstOrderItemRef && firstOrderItemRef.current) {
      firstOrderItemRef.current.className = 'order-item highlight-order-item';
    }
  }

  const handleAddComicButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, comic: IComic | undefined): void => {
    e.stopPropagation();
    if (comic) {
      handleAddComic(comic);
    }
  }

  const handleAddComic = (comic: IComic): void => {
    // console.log(comic);
    // console.log(comic.batch);

    const orderItem = order.orderItems.find((i) => i.comicId === comic.comicId);
    if (orderItem && orderItem !== undefined) {
      // update
      order.orderItems.splice(order.orderItems.indexOf(orderItem), 1);
      orderItem.quantity++;

      // add at top of array
      const newOrderItems = [orderItem, ...order.orderItems,];
      order.orderItems = newOrderItems;
    }
    else {
      // console.log(`comic.comicId ` + comic.comicId)
      // add
      const newOrderItems = [
        createOrderItem(comic, getPriceComicB(comic, comic.batch)),
        ...order.orderItems
      ];
      order.orderItems = newOrderItems;
    }
    updateOrder(order)
      .then(({ data: { order, transactions, credit } }: IOrder[] | any) => {
        update(order, transactions, credit);
      })
      .catch((err) => console.log(err))
  }


  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
    // console.log('clicked');
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const theFile = e.target.files[0];
    setFile(e.target.files[0]);

    Papa.parse(theFile, {
      complete: async function (results) {
        // console.log(results);
        const saleMap = results.data.map(line => {
          // console.log(line);
          return doBreakDown(line as string[]);
        });

        const saleMapFinished = await Promise.all(saleMap);
        const saleMapDone: IEbaySale[] = saleMapFinished.filter((item) => (typeof (item) !== 'boolean')) as IEbaySale[];
        setEbaySales(saleMapDone);
        addEbaySales(order, saleMapDone)
          .then(({ data: { comics, message }, status }) => {
            if (status !== 200) {
              throw new Error('Error! Todo not saved ' + status)
            }
            setStock(comics);
            // console.log('message = ' + message);
            setMessage(message)
          })
          .catch((err) => console.log(err))
      }
    });
  }

  const doBreakDown = async (breakdown: string[]) => {

    if (breakdown.length > 3 && breakdown[1] === `Order` && breakdown[19] !== `--`) {
      const date = new Date(breakdown[0]);
      const orderNumber = breakdown[3];
      const buyerUsername = breakdown[4];
      const buyerName = breakdown[5];
      const deliveryCity = breakdown[6];
      const state = breakdown[7];
      const postcode = breakdown[8];
      const itemTitle = breakdown[19];
      const sku = breakdown[20];
      const quantity = breakdown[21] === "--" ? 0 : parseInt(breakdown[21]);
      const itemSubtotal = breakdown[22] === "--" ? 0 : parseFloat(breakdown[22]);
      const postage = breakdown[23] === "--" ? 0 : parseFloat(breakdown[23]);
      const feeFixed = breakdown[27] === "--" ? 0 : parseFloat(breakdown[29]);
      const feeVariable = breakdown[28] === "--" ? 0 : parseFloat(breakdown[28]);

      const ebaySale: IEbaySale = {
        date,
        orderNumber,
        buyerUsername,
        buyerName,
        deliveryCity,
        state,
        postcode,
        itemTitle,
        sku,
        quantity,
        itemSubtotal,
        postage,
        feeFixed,
        feeVariable,
      }
      console.log(ebaySale);
      return ebaySale;
    }
    return false;
  };

  const isInOrder = (ebaySale: IEbaySale): string => {

    const isIt = order.orderItems.filter(item => item.comicId === getComic(ebaySale)?.comicId);
    return isIt.length > 0 ? 'fade' : '';
  }

  let currentOrderNumber = '';

  const checkCurrentOrderNumber = (ebaySale: IEbaySale): boolean => {

    if (ebaySale.orderNumber === currentOrderNumber) {
      return true;
    }
    else {
      currentOrderNumber = ebaySale.orderNumber;
      return false;
    }
  }

  const getComic = (ebaySale: IEbaySale): IComic | undefined => {

    const isItFound = stocks.find(comic => ebaySale.sku === comic.ebaySku);
    return isItFound;
  }

  return (
    <>
      <article>
        <h1>Ebay Sales Upload</h1>
        <div>Upload a file:</div>
        {/* 👇 Our custom button to select and upload a file */}
        <button onClick={handleUploadClick}>
          Click to Select
        </button>

        {/* 👇 Notice the `display: hidden` on the input */}
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        {file ? (<div>Uploaded <b>{file.name}</b></div>) : ''}

        <hr />

        {message
          ? (
            <>
              <div>&nbsp;</div>
              <div>Result : {message} {stocks?.length}</div>
              <div>&nbsp;</div>
              {ebaySales && ebaySales.map((ebaySale: IEbaySale) => (
                (<>
                  {!checkCurrentOrderNumber(ebaySale) && (<div><br />{formatDate(ebaySale.date)} • {ebaySale.buyerName} • {ebaySale.buyerUsername} </div>)}
                  <div>
                    {getComic(ebaySale) ? <button className='link-button' onClick={(e) => handleAddComicButton(e, getComic(ebaySale))}>add</button> : <span>------</span>} <span className={isInOrder(ebaySale)}>{ebaySale.itemTitle}</span>
                  </div>
                </>)
              ))}

              {/* {ebaySales && ebaySales.map((ebaySale: IEbaySale) => (
                (<>
                  {!checkCurrentOrderNumber(comic) && (<div><br/>{formatDate(getEbaySale(comic)?.date)} • {getEbaySale(comic)?.buyerName} • {getEbaySale(comic)?.buyerUsername} </div>)}
                  <div>
                    <button className='link-button' onClick={(e) => handleAddComicButton(e, comic)}>add</button> <span className={isInOrder(comic)}>{comic.mainDescription + ' ' + comic.variantDescription} </span> 
                  </div>
                </>)
              ))} */}
            </>)
          : (<></>)}

      </article>
      <nav><AdminNavigation /></nav>
      <aside>
        {!state.showMobileOrder &&
          <OrderList
            order={order}
            setOrder={setOrder}
            state={state}
            setState={setState}
            libraries={libraries}
            batches={batches}
            firstOrderItemRef={firstOrderItemRef}
            transactions={transactions}
            setTransactions={setTransactions}
            credit={credit}
            setCredit={setCredit}
            mobile={false}
          />}
      </aside>
    </>
  );
}

export default EbaySalesUpload